<template>
  <div>
    <b-row vs-justify="center">
      <b-col lg="12" cols="12">
        <b-card>
          <b-card-text>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-access-point"></i>
                <code class="mr-2">f002</code>
                <span>mdi-access-point</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-access-point-network"></i>
                <code class="mr-2">f003</code>
                <span>mdi-access-point-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account"></i>
                <code class="mr-2">f004</code>
                <span>mdi-account</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-alert"></i>
                <code class="mr-2">f005</code>
                <span>mdi-account-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-box"></i>
                <code class="mr-2">f006</code>
                <span>mdi-account-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-box-outline"></i>
                <code class="mr-2">f007</code>
                <span>mdi-account-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-check"></i>
                <code class="mr-2">f008</code>
                <span>mdi-account-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-circle"></i>
                <code class="mr-2">f009</code>
                <span>mdi-account-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-convert"></i>
                <code class="mr-2">f00a</code>
                <span>mdi-account-convert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-edit"></i>
                <code class="mr-2">f6bb</code>
                <span>mdi-account-edit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-key"></i>
                <code class="mr-2">f00b</code>
                <span>mdi-account-key</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-minus"></i>
                <code class="mr-2">f00d</code>
                <span>mdi-account-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-multiple"></i>
                <code class="mr-2">f00e</code>
                <span>mdi-account-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-multiple-minus"></i>
                <code class="mr-2">f5d3</code>
                <span>mdi-account-multiple-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-multiple-outline"></i>
                <code class="mr-2">f00f</code>
                <span>mdi-account-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-multiple-plus"></i>
                <code class="mr-2">f010</code>
                <span>mdi-account-multiple-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-network"></i>
                <code class="mr-2">f011</code>
                <span>mdi-account-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-off"></i>
                <code class="mr-2">f012</code>
                <span>mdi-account-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-outline"></i>
                <code class="mr-2">f013</code>
                <span>mdi-account-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-plus"></i>
                <code class="mr-2">f014</code>
                <span>mdi-account-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-remove"></i>
                <code class="mr-2">f015</code>
                <span>mdi-account-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-search"></i>
                <code class="mr-2">f016</code>
                <span>mdi-account-search</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-settings"></i>
                <code class="mr-2">f630</code>
                <span>mdi-account-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-star"></i>
                <code class="mr-2">f017</code>
                <span>mdi-account-star</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-account-switch"></i>
                <code class="mr-2">f019</code>
                <span>mdi-account-switch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-adjust"></i>
                <code class="mr-2">f01a</code>
                <span>mdi-adjust</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-air-conditioner"></i>
                <code class="mr-2">f01b</code>
                <span>mdi-air-conditioner</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-airballoon"></i>
                <code class="mr-2">f01c</code>
                <span>mdi-airballoon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-airplane"></i>
                <code class="mr-2">f01d</code>
                <span>mdi-airplane</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-airplane-landing"></i>
                <code class="mr-2">f5d4</code>
                <span>mdi-airplane-landing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-airplane-off"></i>
                <code class="mr-2">f01e</code>
                <span>mdi-airplane-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-airplane-takeoff"></i>
                <code class="mr-2">f5d5</code>
                <span>mdi-airplane-takeoff</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm"></i>
                <code class="mr-2">f020</code>
                <span>mdi-alarm</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm-check"></i>
                <code class="mr-2">f021</code>
                <span>mdi-alarm-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm-multiple"></i>
                <code class="mr-2">f022</code>
                <span>mdi-alarm-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm-off"></i>
                <code class="mr-2">f023</code>
                <span>mdi-alarm-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm-plus"></i>
                <code class="mr-2">f024</code>
                <span>mdi-alarm-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alarm-snooze"></i>
                <code class="mr-2">f68d</code>
                <span>mdi-alarm-snooze</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-album"></i>
                <code class="mr-2">f025</code>
                <span>mdi-album</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert"></i>
                <code class="mr-2">f026</code>
                <span>mdi-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-box"></i>
                <code class="mr-2">f027</code>
                <span>mdi-alert-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-circle"></i>
                <code class="mr-2">f028</code>
                <span>mdi-alert-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-circle-outline"></i>
                <code class="mr-2">f5d6</code>
                <span>mdi-alert-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-octagon"></i>
                <code class="mr-2">f029</code>
                <span>mdi-alert-octagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-octagram"></i>
                <code class="mr-2">f6bc</code>
                <span>mdi-alert-octagram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alert-outline"></i>
                <code class="mr-2">f02a</code>
                <span>mdi-alert-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-all-inclusive"></i>
                <code class="mr-2">f6bd</code>
                <span>mdi-all-inclusive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alpha"></i>
                <code class="mr-2">f02b</code>
                <span>mdi-alpha</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-alphabetical"></i>
                <code class="mr-2">f02c</code>
                <span>mdi-alphabetical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-altimeter"></i>
                <code class="mr-2">f5d7</code>
                <span>mdi-altimeter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-amazon"></i>
                <code class="mr-2">f02d</code>
                <span>mdi-amazon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-amazon-clouddrive"></i>
                <code class="mr-2">f02e</code>
                <span>mdi-amazon-clouddrive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ambulance"></i>
                <code class="mr-2">f02f</code>
                <span>mdi-ambulance</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-amplifier"></i>
                <code class="mr-2">f030</code>
                <span>mdi-amplifier</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-anchor"></i>
                <code class="mr-2">f031</code>
                <span>mdi-anchor</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-android"></i>
                <code class="mr-2">f032</code>
                <span>mdi-android</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-android-debug-bridge"></i>
                <code class="mr-2">f033</code>
                <span>mdi-android-debug-bridge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-android-studio"></i>
                <code class="mr-2">f034</code>
                <span>mdi-android-studio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-angular"></i>
                <code class="mr-2">f6b1</code>
                <span>mdi-angular</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-angularjs"></i>
                <code class="mr-2">f6be</code>
                <span>mdi-angularjs</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-animation"></i>
                <code class="mr-2">f5d8</code>
                <span>mdi-animation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple"></i>
                <code class="mr-2">f035</code>
                <span>mdi-apple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-finder"></i>
                <code class="mr-2">f036</code>
                <span>mdi-apple-finder</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-ios"></i>
                <code class="mr-2">f037</code>
                <span>mdi-apple-ios</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-keyboard-caps"></i>
                <code class="mr-2">f632</code>
                <span>mdi-apple-keyboard-caps</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-keyboard-command"></i>
                <code class="mr-2">f633</code>
                <span>mdi-apple-keyboard-command</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-keyboard-control"></i>
                <code class="mr-2">f634</code>
                <span>mdi-apple-keyboard-control</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-keyboard-option"></i>
                <code class="mr-2">f635</code>
                <span>mdi-apple-keyboard-option</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-keyboard-shift"></i>
                <code class="mr-2">f636</code>
                <span>mdi-apple-keyboard-shift</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apple-safari"></i>
                <code class="mr-2">f039</code>
                <span>mdi-apple-safari</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-application"></i>
                <code class="mr-2">f614</code>
                <span>mdi-application</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-apps"></i>
                <code class="mr-2">f03b</code>
                <span>mdi-apps</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-archive"></i>
                <code class="mr-2">f03c</code>
                <span>mdi-archive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrange-bring-forward"></i>
                <code class="mr-2">f03d</code>
                <span>mdi-arrange-bring-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrange-bring-to-front"></i>
                <code class="mr-2">f03e</code>
                <span>mdi-arrange-bring-to-front</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrange-send-backward"></i>
                <code class="mr-2">f03f</code>
                <span>mdi-arrange-send-backward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrange-send-to-back"></i>
                <code class="mr-2">f040</code>
                <span>mdi-arrange-send-to-back</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-all"></i>
                <code class="mr-2">f041</code>
                <span>mdi-arrow-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-bottom-left"></i>
                <code class="mr-2">f042</code>
                <span>mdi-arrow-bottom-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-bottom-right"></i>
                <code class="mr-2">f043</code>
                <span>mdi-arrow-bottom-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down"></i>
                <code class="mr-2">f045</code>
                <span>mdi-arrow-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-bold"></i>
                <code class="mr-2">f046</code>
                <span>mdi-arrow-down-bold</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-bold-circle"></i>
                <code class="mr-2">f047</code>
                <span>mdi-arrow-down-bold-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-bold-circle-outline"></i>
                <code class="mr-2">f048</code>
                <span>mdi-arrow-down-bold-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-bold-hexagon-outline"></i>
                <code class="mr-2">f049</code>
                <span>mdi-arrow-down-bold-hexagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-box"></i>
                <code class="mr-2">f6bf</code>
                <span>mdi-arrow-down-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-drop-circle"></i>
                <code class="mr-2">f04a</code>
                <span>mdi-arrow-down-drop-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-down-drop-circle-outline"></i>
                <code class="mr-2">f04b</code>
                <span>mdi-arrow-down-drop-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-expand"></i>
                <code class="mr-2">f616</code>
                <span>mdi-arrow-expand</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-expand-all"></i>
                <code class="mr-2">f04c</code>
                <span>mdi-arrow-expand-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left"></i>
                <code class="mr-2">f04d</code>
                <span>mdi-arrow-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-bold"></i>
                <code class="mr-2">f04e</code>
                <span>mdi-arrow-left-bold</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-bold-circle"></i>
                <code class="mr-2">f04f</code>
                <span>mdi-arrow-left-bold-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-bold-circle-outline"></i>
                <code class="mr-2">f050</code>
                <span>mdi-arrow-left-bold-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-bold-hexagon-outline"></i>
                <code class="mr-2">f051</code>
                <span>mdi-arrow-left-bold-hexagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-box"></i>
                <code class="mr-2">f6c0</code>
                <span>mdi-arrow-left-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-drop-circle"></i>
                <code class="mr-2">f052</code>
                <span>mdi-arrow-left-drop-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-left-drop-circle-outline"></i>
                <code class="mr-2">f053</code>
                <span>mdi-arrow-left-drop-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right"></i>
                <code class="mr-2">f054</code>
                <span>mdi-arrow-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-bold"></i>
                <code class="mr-2">f055</code>
                <span>mdi-arrow-right-bold</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-bold-circle"></i>
                <code class="mr-2">f056</code>
                <span>mdi-arrow-right-bold-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-bold-circle-outline"></i>
                <code class="mr-2">f057</code>
                <span>mdi-arrow-right-bold-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-bold-hexagon-outline"></i>
                <code class="mr-2">f058</code>
                <span>mdi-arrow-right-bold-hexagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-box"></i>
                <code class="mr-2">f6c1</code>
                <span>mdi-arrow-right-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-drop-circle"></i>
                <code class="mr-2">f059</code>
                <span>mdi-arrow-right-drop-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-right-drop-circle-outline"></i>
                <code class="mr-2">f05a</code>
                <span>mdi-arrow-right-drop-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-top-left"></i>
                <code class="mr-2">f05b</code>
                <span>mdi-arrow-top-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-top-right"></i>
                <code class="mr-2">f05c</code>
                <span>mdi-arrow-top-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up"></i>
                <code class="mr-2">f05d</code>
                <span>mdi-arrow-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-bold"></i>
                <code class="mr-2">f05e</code>
                <span>mdi-arrow-up-bold</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-bold-circle"></i>
                <code class="mr-2">f05f</code>
                <span>mdi-arrow-up-bold-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-bold-circle-outline"></i>
                <code class="mr-2">f060</code>
                <span>mdi-arrow-up-bold-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-bold-hexagon-outline"></i>
                <code class="mr-2">f061</code>
                <span>mdi-arrow-up-bold-hexagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-box"></i>
                <code class="mr-2">f6c2</code>
                <span>mdi-arrow-up-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-drop-circle"></i>
                <code class="mr-2">f062</code>
                <span>mdi-arrow-up-drop-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-arrow-up-drop-circle-outline"></i>
                <code class="mr-2">f063</code>
                <span>mdi-arrow-up-drop-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-assistant"></i>
                <code class="mr-2">f064</code>
                <span>mdi-assistant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-asterisk"></i>
                <code class="mr-2">f6c3</code>
                <span>mdi-asterisk</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-at"></i>
                <code class="mr-2">f065</code>
                <span>mdi-at</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-attachment"></i>
                <code class="mr-2">f066</code>
                <span>mdi-attachment</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-auto-fix"></i>
                <code class="mr-2">f068</code>
                <span>mdi-auto-fix</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-auto-upload"></i>
                <code class="mr-2">f069</code>
                <span>mdi-auto-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-autorenew"></i>
                <code class="mr-2">f06a</code>
                <span>mdi-autorenew</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-av-timer"></i>
                <code class="mr-2">f06b</code>
                <span>mdi-av-timer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-baby"></i>
                <code class="mr-2">f06c</code>
                <span>mdi-baby</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-backburger"></i>
                <code class="mr-2">f06d</code>
                <span>mdi-backburger</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-backspace"></i>
                <code class="mr-2">f06e</code>
                <span>mdi-backspace</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-backup-restore"></i>
                <code class="mr-2">f06f</code>
                <span>mdi-backup-restore</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bandcamp"></i>
                <code class="mr-2">f674</code>
                <span>mdi-bandcamp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bank"></i>
                <code class="mr-2">f070</code>
                <span>mdi-bank</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-barcode"></i>
                <code class="mr-2">f071</code>
                <span>mdi-barcode</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-barcode-scan"></i>
                <code class="mr-2">f072</code>
                <span>mdi-barcode-scan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-barley"></i>
                <code class="mr-2">f073</code>
                <span>mdi-barley</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-barrel"></i>
                <code class="mr-2">f074</code>
                <span>mdi-barrel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-basket"></i>
                <code class="mr-2">f076</code>
                <span>mdi-basket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-basket-fill"></i>
                <code class="mr-2">f077</code>
                <span>mdi-basket-fill</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-basket-unfill"></i>
                <code class="mr-2">f078</code>
                <span>mdi-basket-unfill</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery"></i>
                <code class="mr-2">f079</code>
                <span>mdi-battery</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-10"></i>
                <code class="mr-2">f07a</code>
                <span>mdi-battery-10</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-20"></i>
                <code class="mr-2">f07b</code>
                <span>mdi-battery-20</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-30"></i>
                <code class="mr-2">f07c</code>
                <span>mdi-battery-30</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-40"></i>
                <code class="mr-2">f07d</code>
                <span>mdi-battery-40</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-50"></i>
                <code class="mr-2">f07e</code>
                <span>mdi-battery-50</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-60"></i>
                <code class="mr-2">f07f</code>
                <span>mdi-battery-60</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-70"></i>
                <code class="mr-2">f080</code>
                <span>mdi-battery-70</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-80"></i>
                <code class="mr-2">f081</code>
                <span>mdi-battery-80</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-90"></i>
                <code class="mr-2">f082</code>
                <span>mdi-battery-90</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-alert"></i>
                <code class="mr-2">f083</code>
                <span>mdi-battery-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging"></i>
                <code class="mr-2">f084</code>
                <span>mdi-battery-charging</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-100"></i>
                <code class="mr-2">f085</code>
                <span>mdi-battery-charging-100</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-20"></i>
                <code class="mr-2">f086</code>
                <span>mdi-battery-charging-20</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-30"></i>
                <code class="mr-2">f087</code>
                <span>mdi-battery-charging-30</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-40"></i>
                <code class="mr-2">f088</code>
                <span>mdi-battery-charging-40</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-60"></i>
                <code class="mr-2">f089</code>
                <span>mdi-battery-charging-60</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-80"></i>
                <code class="mr-2">f08a</code>
                <span>mdi-battery-charging-80</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-charging-90"></i>
                <code class="mr-2">f08b</code>
                <span>mdi-battery-charging-90</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-minus"></i>
                <code class="mr-2">f08c</code>
                <span>mdi-battery-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-negative"></i>
                <code class="mr-2">f08d</code>
                <span>mdi-battery-negative</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-outline"></i>
                <code class="mr-2">f08e</code>
                <span>mdi-battery-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-plus"></i>
                <code class="mr-2">f08f</code>
                <span>mdi-battery-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-positive"></i>
                <code class="mr-2">f090</code>
                <span>mdi-battery-positive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-battery-unknown"></i>
                <code class="mr-2">f091</code>
                <span>mdi-battery-unknown</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-beach"></i>
                <code class="mr-2">f092</code>
                <span>mdi-beach</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-beaker"></i>
                <code class="mr-2">f68f</code>
                <span>mdi-beaker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-beer"></i>
                <code class="mr-2">f098</code>
                <span>mdi-beer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-behance"></i>
                <code class="mr-2">f099</code>
                <span>mdi-behance</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell"></i>
                <code class="mr-2">f09a</code>
                <span>mdi-bell</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-off"></i>
                <code class="mr-2">f09b</code>
                <span>mdi-bell-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-outline"></i>
                <code class="mr-2">f09c</code>
                <span>mdi-bell-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-plus"></i>
                <code class="mr-2">f09d</code>
                <span>mdi-bell-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-ring"></i>
                <code class="mr-2">f09e</code>
                <span>mdi-bell-ring</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-ring-outline"></i>
                <code class="mr-2">f09f</code>
                <span>mdi-bell-ring-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bell-sleep"></i>
                <code class="mr-2">f0a0</code>
                <span>mdi-bell-sleep</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-beta"></i>
                <code class="mr-2">f0a1</code>
                <span>mdi-beta</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bike"></i>
                <code class="mr-2">f0a3</code>
                <span>mdi-bike</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-binoculars"></i>
                <code class="mr-2">f0a5</code>
                <span>mdi-binoculars</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bio"></i>
                <code class="mr-2">f0a6</code>
                <span>mdi-bio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-biohazard"></i>
                <code class="mr-2">f0a7</code>
                <span>mdi-biohazard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bitbucket"></i>
                <code class="mr-2">f0a8</code>
                <span>mdi-bitbucket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-black-mesa"></i>
                <code class="mr-2">f0a9</code>
                <span>mdi-black-mesa</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blender"></i>
                <code class="mr-2">f0ab</code>
                <span>mdi-blender</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blinds"></i>
                <code class="mr-2">f0ac</code>
                <span>mdi-blinds</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-block-helper"></i>
                <code class="mr-2">f0ad</code>
                <span>mdi-block-helper</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blogger"></i>
                <code class="mr-2">f0ae</code>
                <span>mdi-blogger</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth"></i>
                <code class="mr-2">f0af</code>
                <span>mdi-bluetooth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth-audio"></i>
                <code class="mr-2">f0b0</code>
                <span>mdi-bluetooth-audio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth-connect"></i>
                <code class="mr-2">f0b1</code>
                <span>mdi-bluetooth-connect</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth-off"></i>
                <code class="mr-2">f0b2</code>
                <span>mdi-bluetooth-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth-settings"></i>
                <code class="mr-2">f0b3</code>
                <span>mdi-bluetooth-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bluetooth-transfer"></i>
                <code class="mr-2">f0b4</code>
                <span>mdi-bluetooth-transfer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blur"></i>
                <code class="mr-2">f0b5</code>
                <span>mdi-blur</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blur-linear"></i>
                <code class="mr-2">f0b6</code>
                <span>mdi-blur-linear</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blur-off"></i>
                <code class="mr-2">f0b7</code>
                <span>mdi-blur-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-blur-radial"></i>
                <code class="mr-2">f0b8</code>
                <span>mdi-blur-radial</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bomb"></i>
                <code class="mr-2">f690</code>
                <span>mdi-bomb</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bomb-off"></i>
                <code class="mr-2">f6c4</code>
                <span>mdi-bomb-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bone"></i>
                <code class="mr-2">f0b9</code>
                <span>mdi-bone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book"></i>
                <code class="mr-2">f0ba</code>
                <span>mdi-book</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-minus"></i>
                <code class="mr-2">f5d9</code>
                <span>mdi-book-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-multiple"></i>
                <code class="mr-2">f0bb</code>
                <span>mdi-book-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-open"></i>
                <code class="mr-2">f0bd</code>
                <span>mdi-book-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-open-page-variant"></i>
                <code class="mr-2">f5da</code>
                <span>mdi-book-open-page-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-open-variant"></i>
                <code class="mr-2">f0be</code>
                <span>mdi-book-open-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-plus"></i>
                <code class="mr-2">f5db</code>
                <span>mdi-book-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-book-variant"></i>
                <code class="mr-2">f0bf</code>
                <span>mdi-book-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark"></i>
                <code class="mr-2">f0c0</code>
                <span>mdi-bookmark</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-check"></i>
                <code class="mr-2">f0c1</code>
                <span>mdi-bookmark-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-music"></i>
                <code class="mr-2">f0c2</code>
                <span>mdi-bookmark-music</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-outline"></i>
                <code class="mr-2">f0c3</code>
                <span>mdi-bookmark-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-plus"></i>
                <code class="mr-2">f0c5</code>
                <span>mdi-bookmark-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-plus-outline"></i>
                <code class="mr-2">f0c4</code>
                <span>mdi-bookmark-plus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bookmark-remove"></i>
                <code class="mr-2">f0c6</code>
                <span>mdi-bookmark-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-boombox"></i>
                <code class="mr-2">f5dc</code>
                <span>mdi-boombox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bootstrap"></i>
                <code class="mr-2">f6c5</code>
                <span>mdi-bootstrap</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-all"></i>
                <code class="mr-2">f0c7</code>
                <span>mdi-border-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-bottom"></i>
                <code class="mr-2">f0c8</code>
                <span>mdi-border-bottom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-color"></i>
                <code class="mr-2">f0c9</code>
                <span>mdi-border-color</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-horizontal"></i>
                <code class="mr-2">f0ca</code>
                <span>mdi-border-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-inside"></i>
                <code class="mr-2">f0cb</code>
                <span>mdi-border-inside</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-left"></i>
                <code class="mr-2">f0cc</code>
                <span>mdi-border-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-none"></i>
                <code class="mr-2">f0cd</code>
                <span>mdi-border-none</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-outside"></i>
                <code class="mr-2">f0ce</code>
                <span>mdi-border-outside</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-right"></i>
                <code class="mr-2">f0cf</code>
                <span>mdi-border-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-style"></i>
                <code class="mr-2">f0d0</code>
                <span>mdi-border-style</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-top"></i>
                <code class="mr-2">f0d1</code>
                <span>mdi-border-top</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-border-vertical"></i>
                <code class="mr-2">f0d2</code>
                <span>mdi-border-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bow-tie"></i>
                <code class="mr-2">f677</code>
                <span>mdi-bow-tie</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bowl"></i>
                <code class="mr-2">f617</code>
                <span>mdi-bowl</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bowling"></i>
                <code class="mr-2">f0d3</code>
                <span>mdi-bowling</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-box"></i>
                <code class="mr-2">f0d4</code>
                <span>mdi-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-box-cutter"></i>
                <code class="mr-2">f0d5</code>
                <span>mdi-box-cutter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-box-shadow"></i>
                <code class="mr-2">f637</code>
                <span>mdi-box-shadow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bridge"></i>
                <code class="mr-2">f618</code>
                <span>mdi-bridge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-briefcase"></i>
                <code class="mr-2">f0d6</code>
                <span>mdi-briefcase</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-briefcase-check"></i>
                <code class="mr-2">f0d7</code>
                <span>mdi-briefcase-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-briefcase-download"></i>
                <code class="mr-2">f0d8</code>
                <span>mdi-briefcase-download</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-briefcase-upload"></i>
                <code class="mr-2">f0d9</code>
                <span>mdi-briefcase-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-1"></i>
                <code class="mr-2">f0da</code>
                <span>mdi-brightness-1</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-2"></i>
                <code class="mr-2">f0db</code>
                <span>mdi-brightness-2</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-3"></i>
                <code class="mr-2">f0dc</code>
                <span>mdi-brightness-3</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-4"></i>
                <code class="mr-2">f0dd</code>
                <span>mdi-brightness-4</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-5"></i>
                <code class="mr-2">f0de</code>
                <span>mdi-brightness-5</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-6"></i>
                <code class="mr-2">f0df</code>
                <span>mdi-brightness-6</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-7"></i>
                <code class="mr-2">f0e0</code>
                <span>mdi-brightness-7</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brightness-auto"></i>
                <code class="mr-2">f0e1</code>
                <span>mdi-brightness-auto</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-broom"></i>
                <code class="mr-2">f0e2</code>
                <span>mdi-broom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-brush"></i>
                <code class="mr-2">f0e3</code>
                <span>mdi-brush</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-buffer"></i>
                <code class="mr-2">f619</code>
                <span>mdi-buffer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bug"></i>
                <code class="mr-2">f0e4</code>
                <span>mdi-bug</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bulletin-board"></i>
                <code class="mr-2">f0e5</code>
                <span>mdi-bulletin-board</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bullhorn"></i>
                <code class="mr-2">f0e6</code>
                <span>mdi-bullhorn</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bullseye"></i>
                <code class="mr-2">f5dd</code>
                <span>mdi-bullseye</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-burst-mode"></i>
                <code class="mr-2">f5de</code>
                <span>mdi-burst-mode</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-bus"></i>
                <code class="mr-2">f0e7</code>
                <span>mdi-bus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cached"></i>
                <code class="mr-2">f0e8</code>
                <span>mdi-cached</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cake"></i>
                <code class="mr-2">f0e9</code>
                <span>mdi-cake</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cake-layered"></i>
                <code class="mr-2">f0ea</code>
                <span>mdi-cake-layered</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cake-variant"></i>
                <code class="mr-2">f0eb</code>
                <span>mdi-cake-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calculator"></i>
                <code class="mr-2">f0ec</code>
                <span>mdi-calculator</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar"></i>
                <code class="mr-2">f0ed</code>
                <span>mdi-calendar</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-blank"></i>
                <code class="mr-2">f0ee</code>
                <span>mdi-calendar-blank</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-check"></i>
                <code class="mr-2">f0ef</code>
                <span>mdi-calendar-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-clock"></i>
                <code class="mr-2">f0f0</code>
                <span>mdi-calendar-clock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-multiple"></i>
                <code class="mr-2">f0f1</code>
                <span>mdi-calendar-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-multiple-check"></i>
                <code class="mr-2">f0f2</code>
                <span>mdi-calendar-multiple-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-plus"></i>
                <code class="mr-2">f0f3</code>
                <span>mdi-calendar-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-question"></i>
                <code class="mr-2">f691</code>
                <span>mdi-calendar-question</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-range"></i>
                <code class="mr-2">f678</code>
                <span>mdi-calendar-range</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-remove"></i>
                <code class="mr-2">f0f4</code>
                <span>mdi-calendar-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-text"></i>
                <code class="mr-2">f0f5</code>
                <span>mdi-calendar-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-calendar-today"></i>
                <code class="mr-2">f0f6</code>
                <span>mdi-calendar-today</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-call-made"></i>
                <code class="mr-2">f0f7</code>
                <span>mdi-call-made</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-call-merge"></i>
                <code class="mr-2">f0f8</code>
                <span>mdi-call-merge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-call-missed"></i>
                <code class="mr-2">f0f9</code>
                <span>mdi-call-missed</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-call-received"></i>
                <code class="mr-2">f0fa</code>
                <span>mdi-call-received</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-call-split"></i>
                <code class="mr-2">f0fb</code>
                <span>mdi-call-split</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camcorder"></i>
                <code class="mr-2">f0fc</code>
                <span>mdi-camcorder</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camcorder-off"></i>
                <code class="mr-2">f0ff</code>
                <span>mdi-camcorder-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera"></i>
                <code class="mr-2">f100</code>
                <span>mdi-camera</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-burst"></i>
                <code class="mr-2">f692</code>
                <span>mdi-camera-burst</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-enhance"></i>
                <code class="mr-2">f101</code>
                <span>mdi-camera-enhance</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-front"></i>
                <code class="mr-2">f102</code>
                <span>mdi-camera-front</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-front-variant"></i>
                <code class="mr-2">f103</code>
                <span>mdi-camera-front-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-iris"></i>
                <code class="mr-2">f104</code>
                <span>mdi-camera-iris</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-off"></i>
                <code class="mr-2">f5df</code>
                <span>mdi-camera-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-party-mode"></i>
                <code class="mr-2">f105</code>
                <span>mdi-camera-party-mode</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-rear"></i>
                <code class="mr-2">f106</code>
                <span>mdi-camera-rear</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-rear-variant"></i>
                <code class="mr-2">f107</code>
                <span>mdi-camera-rear-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-switch"></i>
                <code class="mr-2">f108</code>
                <span>mdi-camera-switch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-camera-timer"></i>
                <code class="mr-2">f109</code>
                <span>mdi-camera-timer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-candle"></i>
                <code class="mr-2">f5e2</code>
                <span>mdi-candle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-candycane"></i>
                <code class="mr-2">f10a</code>
                <span>mdi-candycane</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-car"></i>
                <code class="mr-2">f10b</code>
                <span>mdi-car</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-car-battery"></i>
                <code class="mr-2">f10c</code>
                <span>mdi-car-battery</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-car-connected"></i>
                <code class="mr-2">f10d</code>
                <span>mdi-car-connected</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-car-wash"></i>
                <code class="mr-2">f10e</code>
                <span>mdi-car-wash</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cards"></i>
                <code class="mr-2">f638</code>
                <span>mdi-cards</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cards-outline"></i>
                <code class="mr-2">f639</code>
                <span>mdi-cards-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cards-playing-outline"></i>
                <code class="mr-2">f63a</code>
                <span>mdi-cards-playing-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cards-variant"></i>
                <code class="mr-2">f6c6</code>
                <span>mdi-cards-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-carrot"></i>
                <code class="mr-2">f10f</code>
                <span>mdi-carrot</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cart"></i>
                <code class="mr-2">f110</code>
                <span>mdi-cart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cart-off"></i>
                <code class="mr-2">f66b</code>
                <span>mdi-cart-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cart-outline"></i>
                <code class="mr-2">f111</code>
                <span>mdi-cart-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cart-plus"></i>
                <code class="mr-2">f112</code>
                <span>mdi-cart-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-case-sensitive-alt"></i>
                <code class="mr-2">f113</code>
                <span>mdi-case-sensitive-alt</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cash"></i>
                <code class="mr-2">f114</code>
                <span>mdi-cash</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cash-100"></i>
                <code class="mr-2">f115</code>
                <span>mdi-cash-100</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cash-multiple"></i>
                <code class="mr-2">f116</code>
                <span>mdi-cash-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cash-usd"></i>
                <code class="mr-2">f117</code>
                <span>mdi-cash-usd</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cast"></i>
                <code class="mr-2">f118</code>
                <span>mdi-cast</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cast-connected"></i>
                <code class="mr-2">f119</code>
                <span>mdi-cast-connected</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-castle"></i>
                <code class="mr-2">f11a</code>
                <span>mdi-castle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cat"></i>
                <code class="mr-2">f11b</code>
                <span>mdi-cat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone"></i>
                <code class="mr-2">f11c</code>
                <span>mdi-cellphone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-android"></i>
                <code class="mr-2">f11d</code>
                <span>mdi-cellphone-android</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-basic"></i>
                <code class="mr-2">f11e</code>
                <span>mdi-cellphone-basic</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-dock"></i>
                <code class="mr-2">f11f</code>
                <span>mdi-cellphone-dock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-iphone"></i>
                <code class="mr-2">f120</code>
                <span>mdi-cellphone-iphone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-link"></i>
                <code class="mr-2">f121</code>
                <span>mdi-cellphone-link</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-link-off"></i>
                <code class="mr-2">f122</code>
                <span>mdi-cellphone-link-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cellphone-settings"></i>
                <code class="mr-2">f123</code>
                <span>mdi-cellphone-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-certificate"></i>
                <code class="mr-2">f124</code>
                <span>mdi-certificate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chair-school"></i>
                <code class="mr-2">f125</code>
                <span>mdi-chair-school</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-arc"></i>
                <code class="mr-2">f126</code>
                <span>mdi-chart-arc</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-areaspline"></i>
                <code class="mr-2">f127</code>
                <span>mdi-chart-areaspline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-bar"></i>
                <code class="mr-2">f128</code>
                <span>mdi-chart-bar</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-bubble"></i>
                <code class="mr-2">f5e3</code>
                <span>mdi-chart-bubble</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-gantt"></i>
                <code class="mr-2">f66c</code>
                <span>mdi-chart-gantt</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-histogram"></i>
                <code class="mr-2">f129</code>
                <span>mdi-chart-histogram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-line"></i>
                <code class="mr-2">f12a</code>
                <span>mdi-chart-line</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-pie"></i>
                <code class="mr-2">f12b</code>
                <span>mdi-chart-pie</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chart-timeline"></i>
                <code class="mr-2">f66e</code>
                <span>mdi-chart-timeline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-check"></i>
                <code class="mr-2">f12c</code>
                <span>mdi-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-check-all"></i>
                <code class="mr-2">f12d</code>
                <span>mdi-check-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-check-circle"></i>
                <code class="mr-2">f5e0</code>
                <span>mdi-check-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-check-circle-outline"></i>
                <code class="mr-2">f5e1</code>
                <span>mdi-check-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-blank"></i>
                <code class="mr-2">f12e</code>
                <span>mdi-checkbox-blank</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-blank-circle"></i>
                <code class="mr-2">f12f</code>
                <span>mdi-checkbox-blank-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-blank-circle-outline"></i>
                <code class="mr-2">f130</code>
                <span>mdi-checkbox-blank-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-blank-outline"></i>
                <code class="mr-2">f131</code>
                <span>mdi-checkbox-blank-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-marked"></i>
                <code class="mr-2">f132</code>
                <span>mdi-checkbox-marked</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-marked-circle"></i>
                <code class="mr-2">f133</code>
                <span>mdi-checkbox-marked-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-marked-circle-outline"></i>
                <code class="mr-2">f134</code>
                <span>mdi-checkbox-marked-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-marked-outline"></i>
                <code class="mr-2">f135</code>
                <span>mdi-checkbox-marked-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-blank"></i>
                <code class="mr-2">f136</code>
                <span>mdi-checkbox-multiple-blank</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-blank-circle"></i>
                <code class="mr-2">f63b</code>
                <span>mdi-checkbox-multiple-blank-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i
                  class="mr-2 mdi mdi-checkbox-multiple-blank-circle-outline"
                ></i>
                <code class="mr-2">f63c</code>
                <span>mdi-checkbox-multiple-blank-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-blank-outline"></i>
                <code class="mr-2">f137</code>
                <span>mdi-checkbox-multiple-blank-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-marked"></i>
                <code class="mr-2">f138</code>
                <span>mdi-checkbox-multiple-marked</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-marked-circle"></i>
                <code class="mr-2">f63d</code>
                <span>mdi-checkbox-multiple-marked-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i
                  class="mr-2 mdi mdi-checkbox-multiple-marked-circle-outline"
                ></i>
                <code class="mr-2">f63e</code>
                <span>mdi-checkbox-multiple-marked-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkbox-multiple-marked-outline"></i>
                <code class="mr-2">f139</code>
                <span>mdi-checkbox-multiple-marked-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-checkerboard"></i>
                <code class="mr-2">f13a</code>
                <span>mdi-checkerboard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chemical-weapon"></i>
                <code class="mr-2">f13b</code>
                <span>mdi-chemical-weapon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-double-down"></i>
                <code class="mr-2">f13c</code>
                <span>mdi-chevron-double-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-double-left"></i>
                <code class="mr-2">f13d</code>
                <span>mdi-chevron-double-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-double-right"></i>
                <code class="mr-2">f13e</code>
                <span>mdi-chevron-double-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-double-up"></i>
                <code class="mr-2">f13f</code>
                <span>mdi-chevron-double-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-down"></i>
                <code class="mr-2">f140</code>
                <span>mdi-chevron-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-left"></i>
                <code class="mr-2">f141</code>
                <span>mdi-chevron-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-right"></i>
                <code class="mr-2">f142</code>
                <span>mdi-chevron-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chevron-up"></i>
                <code class="mr-2">f143</code>
                <span>mdi-chevron-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-chip"></i>
                <code class="mr-2">f61a</code>
                <span>mdi-chip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-church"></i>
                <code class="mr-2">f144</code>
                <span>mdi-church</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cisco-webex"></i>
                <code class="mr-2">f145</code>
                <span>mdi-cisco-webex</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-city"></i>
                <code class="mr-2">f146</code>
                <span>mdi-city</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard"></i>
                <code class="mr-2">f147</code>
                <span>mdi-clipboard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-account"></i>
                <code class="mr-2">f148</code>
                <span>mdi-clipboard-account</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-alert"></i>
                <code class="mr-2">f149</code>
                <span>mdi-clipboard-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-arrow-down"></i>
                <code class="mr-2">f14a</code>
                <span>mdi-clipboard-arrow-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-arrow-left"></i>
                <code class="mr-2">f14b</code>
                <span>mdi-clipboard-arrow-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-check"></i>
                <code class="mr-2">f14c</code>
                <span>mdi-clipboard-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-flow"></i>
                <code class="mr-2">f6c7</code>
                <span>mdi-clipboard-flow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-outline"></i>
                <code class="mr-2">f14d</code>
                <span>mdi-clipboard-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clipboard-text"></i>
                <code class="mr-2">f14e</code>
                <span>mdi-clipboard-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clippy"></i>
                <code class="mr-2">f14f</code>
                <span>mdi-clippy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock"></i>
                <code class="mr-2">f150</code>
                <span>mdi-clock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-alert"></i>
                <code class="mr-2">f5ce</code>
                <span>mdi-clock-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-end"></i>
                <code class="mr-2">f151</code>
                <span>mdi-clock-end</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-fast"></i>
                <code class="mr-2">f152</code>
                <span>mdi-clock-fast</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-in"></i>
                <code class="mr-2">f153</code>
                <span>mdi-clock-in</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-out"></i>
                <code class="mr-2">f154</code>
                <span>mdi-clock-out</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-clock-start"></i>
                <code class="mr-2">f155</code>
                <span>mdi-clock-start</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close"></i>
                <code class="mr-2">f156</code>
                <span>mdi-close</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-box"></i>
                <code class="mr-2">f157</code>
                <span>mdi-close-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-box-outline"></i>
                <code class="mr-2">f158</code>
                <span>mdi-close-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-circle"></i>
                <code class="mr-2">f159</code>
                <span>mdi-close-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-circle-outline"></i>
                <code class="mr-2">f15a</code>
                <span>mdi-close-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-network"></i>
                <code class="mr-2">f15b</code>
                <span>mdi-close-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-octagon"></i>
                <code class="mr-2">f15c</code>
                <span>mdi-close-octagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-octagon-outline"></i>
                <code class="mr-2">f15d</code>
                <span>mdi-close-octagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-close-outline"></i>
                <code class="mr-2">f6c8</code>
                <span>mdi-close-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-closed-caption"></i>
                <code class="mr-2">f15e</code>
                <span>mdi-closed-caption</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud"></i>
                <code class="mr-2">f15f</code>
                <span>mdi-cloud</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-check"></i>
                <code class="mr-2">f160</code>
                <span>mdi-cloud-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-circle"></i>
                <code class="mr-2">f161</code>
                <span>mdi-cloud-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-download"></i>
                <code class="mr-2">f162</code>
                <span>mdi-cloud-download</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-outline"></i>
                <code class="mr-2">f163</code>
                <span>mdi-cloud-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-outline-off"></i>
                <code class="mr-2">f164</code>
                <span>mdi-cloud-outline-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-print"></i>
                <code class="mr-2">f165</code>
                <span>mdi-cloud-print</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-print-outline"></i>
                <code class="mr-2">f166</code>
                <span>mdi-cloud-print-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-sync"></i>
                <code class="mr-2">f63f</code>
                <span>mdi-cloud-sync</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cloud-upload"></i>
                <code class="mr-2">f167</code>
                <span>mdi-cloud-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-array"></i>
                <code class="mr-2">f168</code>
                <span>mdi-code-array</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-braces"></i>
                <code class="mr-2">f169</code>
                <span>mdi-code-braces</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-brackets"></i>
                <code class="mr-2">f16a</code>
                <span>mdi-code-brackets</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-equal"></i>
                <code class="mr-2">f16b</code>
                <span>mdi-code-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-greater-than"></i>
                <code class="mr-2">f16c</code>
                <span>mdi-code-greater-than</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-greater-than-or-equal"></i>
                <code class="mr-2">f16d</code>
                <span>mdi-code-greater-than-or-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-less-than"></i>
                <code class="mr-2">f16e</code>
                <span>mdi-code-less-than</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-less-than-or-equal"></i>
                <code class="mr-2">f16f</code>
                <span>mdi-code-less-than-or-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-not-equal"></i>
                <code class="mr-2">f170</code>
                <span>mdi-code-not-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-not-equal-variant"></i>
                <code class="mr-2">f171</code>
                <span>mdi-code-not-equal-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-parentheses"></i>
                <code class="mr-2">f172</code>
                <span>mdi-code-parentheses</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-string"></i>
                <code class="mr-2">f173</code>
                <span>mdi-code-string</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-tags"></i>
                <code class="mr-2">f174</code>
                <span>mdi-code-tags</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-code-tags-check"></i>
                <code class="mr-2">f693</code>
                <span>mdi-code-tags-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-codepen"></i>
                <code class="mr-2">f175</code>
                <span>mdi-codepen</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-coffee"></i>
                <code class="mr-2">f176</code>
                <span>mdi-coffee</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-coffee-outline"></i>
                <code class="mr-2">f6c9</code>
                <span>mdi-coffee-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-coffee-to-go"></i>
                <code class="mr-2">f177</code>
                <span>mdi-coffee-to-go</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-coin"></i>
                <code class="mr-2">f178</code>
                <span>mdi-coin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-coins"></i>
                <code class="mr-2">f694</code>
                <span>mdi-coins</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-collage"></i>
                <code class="mr-2">f640</code>
                <span>mdi-collage</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-color-helper"></i>
                <code class="mr-2">f179</code>
                <span>mdi-color-helper</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment"></i>
                <code class="mr-2">f17a</code>
                <span>mdi-comment</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-account"></i>
                <code class="mr-2">f17b</code>
                <span>mdi-comment-account</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-account-outline"></i>
                <code class="mr-2">f17c</code>
                <span>mdi-comment-account-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-alert"></i>
                <code class="mr-2">f17d</code>
                <span>mdi-comment-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-alert-outline"></i>
                <code class="mr-2">f17e</code>
                <span>mdi-comment-alert-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-check"></i>
                <code class="mr-2">f17f</code>
                <span>mdi-comment-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-check-outline"></i>
                <code class="mr-2">f180</code>
                <span>mdi-comment-check-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-multiple-outline"></i>
                <code class="mr-2">f181</code>
                <span>mdi-comment-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-outline"></i>
                <code class="mr-2">f182</code>
                <span>mdi-comment-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-plus-outline"></i>
                <code class="mr-2">f183</code>
                <span>mdi-comment-plus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-processing"></i>
                <code class="mr-2">f184</code>
                <span>mdi-comment-processing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-processing-outline"></i>
                <code class="mr-2">f185</code>
                <span>mdi-comment-processing-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-question-outline"></i>
                <code class="mr-2">f186</code>
                <span>mdi-comment-question-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-remove-outline"></i>
                <code class="mr-2">f187</code>
                <span>mdi-comment-remove-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-text"></i>
                <code class="mr-2">f188</code>
                <span>mdi-comment-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-comment-text-outline"></i>
                <code class="mr-2">f189</code>
                <span>mdi-comment-text-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-compare"></i>
                <code class="mr-2">f18a</code>
                <span>mdi-compare</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-compass"></i>
                <code class="mr-2">f18b</code>
                <span>mdi-compass</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-compass-outline"></i>
                <code class="mr-2">f18c</code>
                <span>mdi-compass-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-console"></i>
                <code class="mr-2">f18d</code>
                <span>mdi-console</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-contacts"></i>
                <code class="mr-2">f6ca</code>
                <span>mdi-contacts</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-copy"></i>
                <code class="mr-2">f18f</code>
                <span>mdi-content-copy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-cut"></i>
                <code class="mr-2">f190</code>
                <span>mdi-content-cut</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-duplicate"></i>
                <code class="mr-2">f191</code>
                <span>mdi-content-duplicate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-paste"></i>
                <code class="mr-2">f192</code>
                <span>mdi-content-paste</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-save"></i>
                <code class="mr-2">f193</code>
                <span>mdi-content-save</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-save-all"></i>
                <code class="mr-2">f194</code>
                <span>mdi-content-save-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-content-save-settings"></i>
                <code class="mr-2">f61b</code>
                <span>mdi-content-save-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-contrast"></i>
                <code class="mr-2">f195</code>
                <span>mdi-contrast</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-contrast-box"></i>
                <code class="mr-2">f196</code>
                <span>mdi-contrast-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-contrast-circle"></i>
                <code class="mr-2">f197</code>
                <span>mdi-contrast-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cookie"></i>
                <code class="mr-2">f198</code>
                <span>mdi-cookie</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-copyright"></i>
                <code class="mr-2">f5e6</code>
                <span>mdi-copyright</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-counter"></i>
                <code class="mr-2">f199</code>
                <span>mdi-counter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cow"></i>
                <code class="mr-2">f19a</code>
                <span>mdi-cow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-creation"></i>
                <code class="mr-2">f1c9</code>
                <span>mdi-creation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-credit-card"></i>
                <code class="mr-2">f19b</code>
                <span>mdi-credit-card</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-credit-card-multiple"></i>
                <code class="mr-2">f19c</code>
                <span>mdi-credit-card-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-credit-card-off"></i>
                <code class="mr-2">f5e4</code>
                <span>mdi-credit-card-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-credit-card-plus"></i>
                <code class="mr-2">f675</code>
                <span>mdi-credit-card-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-credit-card-scan"></i>
                <code class="mr-2">f19d</code>
                <span>mdi-credit-card-scan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop"></i>
                <code class="mr-2">f19e</code>
                <span>mdi-crop</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop-free"></i>
                <code class="mr-2">f19f</code>
                <span>mdi-crop-free</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop-landscape"></i>
                <code class="mr-2">f1a0</code>
                <span>mdi-crop-landscape</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop-portrait"></i>
                <code class="mr-2">f1a1</code>
                <span>mdi-crop-portrait</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop-rotate"></i>
                <code class="mr-2">f695</code>
                <span>mdi-crop-rotate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crop-square"></i>
                <code class="mr-2">f1a2</code>
                <span>mdi-crop-square</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crosshairs"></i>
                <code class="mr-2">f1a3</code>
                <span>mdi-crosshairs</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crosshairs-gps"></i>
                <code class="mr-2">f1a4</code>
                <span>mdi-crosshairs-gps</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-crown"></i>
                <code class="mr-2">f1a5</code>
                <span>mdi-crown</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cube"></i>
                <code class="mr-2">f1a6</code>
                <span>mdi-cube</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cube-outline"></i>
                <code class="mr-2">f1a7</code>
                <span>mdi-cube-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cube-send"></i>
                <code class="mr-2">f1a8</code>
                <span>mdi-cube-send</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cube-unfolded"></i>
                <code class="mr-2">f1a9</code>
                <span>mdi-cube-unfolded</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cup"></i>
                <code class="mr-2">f1aa</code>
                <span>mdi-cup</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cup-off"></i>
                <code class="mr-2">f5e5</code>
                <span>mdi-cup-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cup-water"></i>
                <code class="mr-2">f1ab</code>
                <span>mdi-cup-water</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-btc"></i>
                <code class="mr-2">f1ac</code>
                <span>mdi-currency-btc</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-eur"></i>
                <code class="mr-2">f1ad</code>
                <span>mdi-currency-eur</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-gbp"></i>
                <code class="mr-2">f1ae</code>
                <span>mdi-currency-gbp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-inr"></i>
                <code class="mr-2">f1af</code>
                <span>mdi-currency-inr</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-ngn"></i>
                <code class="mr-2">f1b0</code>
                <span>mdi-currency-ngn</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-rub"></i>
                <code class="mr-2">f1b1</code>
                <span>mdi-currency-rub</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-try"></i>
                <code class="mr-2">f1b2</code>
                <span>mdi-currency-try</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-usd"></i>
                <code class="mr-2">f1b3</code>
                <span>mdi-currency-usd</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-currency-usd-off"></i>
                <code class="mr-2">f679</code>
                <span>mdi-currency-usd-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cursor-default"></i>
                <code class="mr-2">f1b4</code>
                <span>mdi-cursor-default</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cursor-default-outline"></i>
                <code class="mr-2">f1b5</code>
                <span>mdi-cursor-default-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cursor-move"></i>
                <code class="mr-2">f1b6</code>
                <span>mdi-cursor-move</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cursor-pointer"></i>
                <code class="mr-2">f1b7</code>
                <span>mdi-cursor-pointer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-cursor-text"></i>
                <code class="mr-2">f5e7</code>
                <span>mdi-cursor-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-database"></i>
                <code class="mr-2">f1b8</code>
                <span>mdi-database</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-database-minus"></i>
                <code class="mr-2">f1b9</code>
                <span>mdi-database-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-database-plus"></i>
                <code class="mr-2">f1ba</code>
                <span>mdi-database-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-debug-step-into"></i>
                <code class="mr-2">f1bb</code>
                <span>mdi-debug-step-into</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-debug-step-out"></i>
                <code class="mr-2">f1bc</code>
                <span>mdi-debug-step-out</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-debug-step-over"></i>
                <code class="mr-2">f1bd</code>
                <span>mdi-debug-step-over</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-decimal-decrease"></i>
                <code class="mr-2">f1be</code>
                <span>mdi-decimal-decrease</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-decimal-increase"></i>
                <code class="mr-2">f1bf</code>
                <span>mdi-decimal-increase</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete"></i>
                <code class="mr-2">f1c0</code>
                <span>mdi-delete</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete-circle"></i>
                <code class="mr-2">f682</code>
                <span>mdi-delete-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete-empty"></i>
                <code class="mr-2">f6cb</code>
                <span>mdi-delete-empty</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete-forever"></i>
                <code class="mr-2">f5e8</code>
                <span>mdi-delete-forever</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete-sweep"></i>
                <code class="mr-2">f5e9</code>
                <span>mdi-delete-sweep</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delete-variant"></i>
                <code class="mr-2">f1c1</code>
                <span>mdi-delete-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-delta"></i>
                <code class="mr-2">f1c2</code>
                <span>mdi-delta</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-deskphone"></i>
                <code class="mr-2">f1c3</code>
                <span>mdi-deskphone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-desktop-mac"></i>
                <code class="mr-2">f1c4</code>
                <span>mdi-desktop-mac</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-desktop-tower"></i>
                <code class="mr-2">f1c5</code>
                <span>mdi-desktop-tower</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-details"></i>
                <code class="mr-2">f1c6</code>
                <span>mdi-details</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-developer-board"></i>
                <code class="mr-2">f696</code>
                <span>mdi-developer-board</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-deviantart"></i>
                <code class="mr-2">f1c7</code>
                <span>mdi-deviantart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dialpad"></i>
                <code class="mr-2">f61c</code>
                <span>mdi-dialpad</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-diamond"></i>
                <code class="mr-2">f1c8</code>
                <span>mdi-diamond</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-1"></i>
                <code class="mr-2">f1ca</code>
                <span>mdi-dice-1</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-2"></i>
                <code class="mr-2">f1cb</code>
                <span>mdi-dice-2</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-3"></i>
                <code class="mr-2">f1cc</code>
                <span>mdi-dice-3</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-4"></i>
                <code class="mr-2">f1cd</code>
                <span>mdi-dice-4</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-5"></i>
                <code class="mr-2">f1ce</code>
                <span>mdi-dice-5</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-6"></i>
                <code class="mr-2">f1cf</code>
                <span>mdi-dice-6</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-d20"></i>
                <code class="mr-2">f5ea</code>
                <span>mdi-dice-d20</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-d4"></i>
                <code class="mr-2">f5eb</code>
                <span>mdi-dice-d4</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-d6"></i>
                <code class="mr-2">f5ec</code>
                <span>mdi-dice-d6</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dice-d8"></i>
                <code class="mr-2">f5ed</code>
                <span>mdi-dice-d8</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dictionary"></i>
                <code class="mr-2">f61d</code>
                <span>mdi-dictionary</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-directions"></i>
                <code class="mr-2">f1d0</code>
                <span>mdi-directions</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-directions-fork"></i>
                <code class="mr-2">f641</code>
                <span>mdi-directions-fork</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-discord"></i>
                <code class="mr-2">f66f</code>
                <span>mdi-discord</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-disqus"></i>
                <code class="mr-2">f1d2</code>
                <span>mdi-disqus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-division"></i>
                <code class="mr-2">f1d4</code>
                <span>mdi-division</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-division-box"></i>
                <code class="mr-2">f1d5</code>
                <span>mdi-division-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dna"></i>
                <code class="mr-2">f683</code>
                <span>mdi-dna</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dns"></i>
                <code class="mr-2">f1d6</code>
                <span>mdi-dns</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-do-not-disturb"></i>
                <code class="mr-2">f697</code>
                <span>mdi-do-not-disturb</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-do-not-disturb-off"></i>
                <code class="mr-2">f698</code>
                <span>mdi-do-not-disturb-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dolby"></i>
                <code class="mr-2">f6b2</code>
                <span>mdi-dolby</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-domain"></i>
                <code class="mr-2">f1d7</code>
                <span>mdi-domain</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dots-horizontal"></i>
                <code class="mr-2">f1d8</code>
                <span>mdi-dots-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dots-vertical"></i>
                <code class="mr-2">f1d9</code>
                <span>mdi-dots-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-douban"></i>
                <code class="mr-2">f699</code>
                <span>mdi-douban</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-download"></i>
                <code class="mr-2">f1da</code>
                <span>mdi-download</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drag"></i>
                <code class="mr-2">f1db</code>
                <span>mdi-drag</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drag-horizontal"></i>
                <code class="mr-2">f1dc</code>
                <span>mdi-drag-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drag-vertical"></i>
                <code class="mr-2">f1dd</code>
                <span>mdi-drag-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drawing"></i>
                <code class="mr-2">f1de</code>
                <span>mdi-drawing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drawing-box"></i>
                <code class="mr-2">f1df</code>
                <span>mdi-drawing-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drone"></i>
                <code class="mr-2">f1e2</code>
                <span>mdi-drone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dropbox"></i>
                <code class="mr-2">f1e3</code>
                <span>mdi-dropbox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-drupal"></i>
                <code class="mr-2">f1e4</code>
                <span>mdi-drupal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-duck"></i>
                <code class="mr-2">f1e5</code>
                <span>mdi-duck</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-dumbbell"></i>
                <code class="mr-2">f1e6</code>
                <span>mdi-dumbbell</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-earth"></i>
                <code class="mr-2">f1e7</code>
                <span>mdi-earth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-earth-box"></i>
                <code class="mr-2">f6cc</code>
                <span>mdi-earth-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-earth-box-off"></i>
                <code class="mr-2">f6cd</code>
                <span>mdi-earth-box-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-earth-off"></i>
                <code class="mr-2">f1e8</code>
                <span>mdi-earth-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eject"></i>
                <code class="mr-2">f1ea</code>
                <span>mdi-eject</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-elevation-decline"></i>
                <code class="mr-2">f1eb</code>
                <span>mdi-elevation-decline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-elevation-rise"></i>
                <code class="mr-2">f1ec</code>
                <span>mdi-elevation-rise</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-elevator"></i>
                <code class="mr-2">f1ed</code>
                <span>mdi-elevator</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email"></i>
                <code class="mr-2">f1ee</code>
                <span>mdi-email</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-alert"></i>
                <code class="mr-2">f6ce</code>
                <span>mdi-email-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-open"></i>
                <code class="mr-2">f1ef</code>
                <span>mdi-email-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-open-outline"></i>
                <code class="mr-2">f5ef</code>
                <span>mdi-email-open-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-outline"></i>
                <code class="mr-2">f1f0</code>
                <span>mdi-email-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-secure"></i>
                <code class="mr-2">f1f1</code>
                <span>mdi-email-secure</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-email-variant"></i>
                <code class="mr-2">f5f0</code>
                <span>mdi-email-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emby"></i>
                <code class="mr-2">f6b3</code>
                <span>mdi-emby</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon"></i>
                <code class="mr-2">f1f2</code>
                <span>mdi-emoticon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-cool"></i>
                <code class="mr-2">f1f3</code>
                <span>mdi-emoticon-cool</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-dead"></i>
                <code class="mr-2">f69a</code>
                <span>mdi-emoticon-dead</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-devil"></i>
                <code class="mr-2">f1f4</code>
                <span>mdi-emoticon-devil</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-excited"></i>
                <code class="mr-2">f69b</code>
                <span>mdi-emoticon-excited</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-happy"></i>
                <code class="mr-2">f1f5</code>
                <span>mdi-emoticon-happy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-neutral"></i>
                <code class="mr-2">f1f6</code>
                <span>mdi-emoticon-neutral</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-poop"></i>
                <code class="mr-2">f1f7</code>
                <span>mdi-emoticon-poop</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-sad"></i>
                <code class="mr-2">f1f8</code>
                <span>mdi-emoticon-sad</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-emoticon-tongue"></i>
                <code class="mr-2">f1f9</code>
                <span>mdi-emoticon-tongue</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-engine"></i>
                <code class="mr-2">f1fa</code>
                <span>mdi-engine</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-engine-outline"></i>
                <code class="mr-2">f1fb</code>
                <span>mdi-engine-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-equal"></i>
                <code class="mr-2">f1fc</code>
                <span>mdi-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-equal-box"></i>
                <code class="mr-2">f1fd</code>
                <span>mdi-equal-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eraser"></i>
                <code class="mr-2">f1fe</code>
                <span>mdi-eraser</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eraser-variant"></i>
                <code class="mr-2">f642</code>
                <span>mdi-eraser-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-escalator"></i>
                <code class="mr-2">f1ff</code>
                <span>mdi-escalator</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ethernet"></i>
                <code class="mr-2">f200</code>
                <span>mdi-ethernet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ethernet-cable"></i>
                <code class="mr-2">f201</code>
                <span>mdi-ethernet-cable</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ethernet-cable-off"></i>
                <code class="mr-2">f202</code>
                <span>mdi-ethernet-cable-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ev-station"></i>
                <code class="mr-2">f5f1</code>
                <span>mdi-ev-station</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-evernote"></i>
                <code class="mr-2">f204</code>
                <span>mdi-evernote</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-exclamation"></i>
                <code class="mr-2">f205</code>
                <span>mdi-exclamation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-exit-to-app"></i>
                <code class="mr-2">f206</code>
                <span>mdi-exit-to-app</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-export"></i>
                <code class="mr-2">f207</code>
                <span>mdi-export</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eye"></i>
                <code class="mr-2">f208</code>
                <span>mdi-eye</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eye-off"></i>
                <code class="mr-2">f209</code>
                <span>mdi-eye-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eye-outline"></i>
                <code class="mr-2">f6cf</code>
                <span>mdi-eye-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eye-outline-off"></i>
                <code class="mr-2">f6d0</code>
                <span>mdi-eye-outline-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eyedropper"></i>
                <code class="mr-2">f20a</code>
                <span>mdi-eyedropper</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-eyedropper-variant"></i>
                <code class="mr-2">f20b</code>
                <span>mdi-eyedropper-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-face"></i>
                <code class="mr-2">f643</code>
                <span>mdi-face</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-face-profile"></i>
                <code class="mr-2">f644</code>
                <span>mdi-face-profile</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-facebook"></i>
                <code class="mr-2">f20c</code>
                <span>mdi-facebook</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-facebook-box"></i>
                <code class="mr-2">f20d</code>
                <span>mdi-facebook-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-facebook-messenger"></i>
                <code class="mr-2">f20e</code>
                <span>mdi-facebook-messenger</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-factory"></i>
                <code class="mr-2">f20f</code>
                <span>mdi-factory</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fan"></i>
                <code class="mr-2">f210</code>
                <span>mdi-fan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fast-forward"></i>
                <code class="mr-2">f211</code>
                <span>mdi-fast-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fast-forward-outline"></i>
                <code class="mr-2">f6d1</code>
                <span>mdi-fast-forward-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fax"></i>
                <code class="mr-2">f212</code>
                <span>mdi-fax</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-feather"></i>
                <code class="mr-2">f6d2</code>
                <span>mdi-feather</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ferry"></i>
                <code class="mr-2">f213</code>
                <span>mdi-ferry</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file"></i>
                <code class="mr-2">f214</code>
                <span>mdi-file</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-chart"></i>
                <code class="mr-2">f215</code>
                <span>mdi-file-chart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-check"></i>
                <code class="mr-2">f216</code>
                <span>mdi-file-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-cloud"></i>
                <code class="mr-2">f217</code>
                <span>mdi-file-cloud</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-delimited"></i>
                <code class="mr-2">f218</code>
                <span>mdi-file-delimited</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-document"></i>
                <code class="mr-2">f219</code>
                <span>mdi-file-document</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-document-box"></i>
                <code class="mr-2">f21a</code>
                <span>mdi-file-document-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-excel"></i>
                <code class="mr-2">f21b</code>
                <span>mdi-file-excel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-excel-box"></i>
                <code class="mr-2">f21c</code>
                <span>mdi-file-excel-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-export"></i>
                <code class="mr-2">f21d</code>
                <span>mdi-file-export</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-find"></i>
                <code class="mr-2">f21e</code>
                <span>mdi-file-find</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-hidden"></i>
                <code class="mr-2">f613</code>
                <span>mdi-file-hidden</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-image"></i>
                <code class="mr-2">f21f</code>
                <span>mdi-file-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-import"></i>
                <code class="mr-2">f220</code>
                <span>mdi-file-import</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-lock"></i>
                <code class="mr-2">f221</code>
                <span>mdi-file-lock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-multiple"></i>
                <code class="mr-2">f222</code>
                <span>mdi-file-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-music"></i>
                <code class="mr-2">f223</code>
                <span>mdi-file-music</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-outline"></i>
                <code class="mr-2">f224</code>
                <span>mdi-file-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-pdf"></i>
                <code class="mr-2">f225</code>
                <span>mdi-file-pdf</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-pdf-box"></i>
                <code class="mr-2">f226</code>
                <span>mdi-file-pdf-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-powerpoint"></i>
                <code class="mr-2">f227</code>
                <span>mdi-file-powerpoint</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-powerpoint-box"></i>
                <code class="mr-2">f228</code>
                <span>mdi-file-powerpoint-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-presentation-box"></i>
                <code class="mr-2">f229</code>
                <span>mdi-file-presentation-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-restore"></i>
                <code class="mr-2">f670</code>
                <span>mdi-file-restore</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-send"></i>
                <code class="mr-2">f22a</code>
                <span>mdi-file-send</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-tree"></i>
                <code class="mr-2">f645</code>
                <span>mdi-file-tree</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-video"></i>
                <code class="mr-2">f22b</code>
                <span>mdi-file-video</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-word"></i>
                <code class="mr-2">f22c</code>
                <span>mdi-file-word</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-word-box"></i>
                <code class="mr-2">f22d</code>
                <span>mdi-file-word-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-file-xml"></i>
                <code class="mr-2">f22e</code>
                <span>mdi-file-xml</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-film"></i>
                <code class="mr-2">f22f</code>
                <span>mdi-film</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filmstrip"></i>
                <code class="mr-2">f230</code>
                <span>mdi-filmstrip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filmstrip-off"></i>
                <code class="mr-2">f231</code>
                <span>mdi-filmstrip-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filter"></i>
                <code class="mr-2">f232</code>
                <span>mdi-filter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filter-outline"></i>
                <code class="mr-2">f233</code>
                <span>mdi-filter-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filter-remove"></i>
                <code class="mr-2">f234</code>
                <span>mdi-filter-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filter-remove-outline"></i>
                <code class="mr-2">f235</code>
                <span>mdi-filter-remove-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-filter-variant"></i>
                <code class="mr-2">f236</code>
                <span>mdi-filter-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-find-replace"></i>
                <code class="mr-2">f6d3</code>
                <span>mdi-find-replace</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fingerprint"></i>
                <code class="mr-2">f237</code>
                <span>mdi-fingerprint</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fire"></i>
                <code class="mr-2">f238</code>
                <span>mdi-fire</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-firefox"></i>
                <code class="mr-2">f239</code>
                <span>mdi-firefox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fish"></i>
                <code class="mr-2">f23a</code>
                <span>mdi-fish</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag"></i>
                <code class="mr-2">f23b</code>
                <span>mdi-flag</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag-checkered"></i>
                <code class="mr-2">f23c</code>
                <span>mdi-flag-checkered</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag-outline"></i>
                <code class="mr-2">f23d</code>
                <span>mdi-flag-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag-outline-variant"></i>
                <code class="mr-2">f23e</code>
                <span>mdi-flag-outline-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag-triangle"></i>
                <code class="mr-2">f23f</code>
                <span>mdi-flag-triangle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flag-variant"></i>
                <code class="mr-2">f240</code>
                <span>mdi-flag-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flash"></i>
                <code class="mr-2">f241</code>
                <span>mdi-flash</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flash-auto"></i>
                <code class="mr-2">f242</code>
                <span>mdi-flash-auto</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flash-off"></i>
                <code class="mr-2">f243</code>
                <span>mdi-flash-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flash-outline"></i>
                <code class="mr-2">f6d4</code>
                <span>mdi-flash-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flash-red-eye"></i>
                <code class="mr-2">f67a</code>
                <span>mdi-flash-red-eye</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flashlight"></i>
                <code class="mr-2">f244</code>
                <span>mdi-flashlight</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flashlight-off"></i>
                <code class="mr-2">f245</code>
                <span>mdi-flashlight-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flask"></i>
                <code class="mr-2">f093</code>
                <span>mdi-flask</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flask-empty"></i>
                <code class="mr-2">f094</code>
                <span>mdi-flask-empty</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flask-empty-outline"></i>
                <code class="mr-2">f095</code>
                <span>mdi-flask-empty-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flask-outline"></i>
                <code class="mr-2">f096</code>
                <span>mdi-flask-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flip-to-back"></i>
                <code class="mr-2">f247</code>
                <span>mdi-flip-to-back</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flip-to-front"></i>
                <code class="mr-2">f248</code>
                <span>mdi-flip-to-front</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-floppy"></i>
                <code class="mr-2">f249</code>
                <span>mdi-floppy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-flower"></i>
                <code class="mr-2">f24a</code>
                <span>mdi-flower</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder"></i>
                <code class="mr-2">f24b</code>
                <span>mdi-folder</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-account"></i>
                <code class="mr-2">f24c</code>
                <span>mdi-folder-account</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-download"></i>
                <code class="mr-2">f24d</code>
                <span>mdi-folder-download</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-google-drive"></i>
                <code class="mr-2">f24e</code>
                <span>mdi-folder-google-drive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-image"></i>
                <code class="mr-2">f24f</code>
                <span>mdi-folder-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-lock"></i>
                <code class="mr-2">f250</code>
                <span>mdi-folder-lock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-lock-open"></i>
                <code class="mr-2">f251</code>
                <span>mdi-folder-lock-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-move"></i>
                <code class="mr-2">f252</code>
                <span>mdi-folder-move</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-multiple"></i>
                <code class="mr-2">f253</code>
                <span>mdi-folder-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-multiple-image"></i>
                <code class="mr-2">f254</code>
                <span>mdi-folder-multiple-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-multiple-outline"></i>
                <code class="mr-2">f255</code>
                <span>mdi-folder-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-outline"></i>
                <code class="mr-2">f256</code>
                <span>mdi-folder-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-plus"></i>
                <code class="mr-2">f257</code>
                <span>mdi-folder-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-remove"></i>
                <code class="mr-2">f258</code>
                <span>mdi-folder-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-star"></i>
                <code class="mr-2">f69c</code>
                <span>mdi-folder-star</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-folder-upload"></i>
                <code class="mr-2">f259</code>
                <span>mdi-folder-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-font-awesome"></i>
                <code class="mr-2">f03a</code>
                <span>mdi-font-awesome</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-food"></i>
                <code class="mr-2">f25a</code>
                <span>mdi-food</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-food-apple"></i>
                <code class="mr-2">f25b</code>
                <span>mdi-food-apple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-food-fork-drink"></i>
                <code class="mr-2">f5f2</code>
                <span>mdi-food-fork-drink</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-food-off"></i>
                <code class="mr-2">f5f3</code>
                <span>mdi-food-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-food-variant"></i>
                <code class="mr-2">f25c</code>
                <span>mdi-food-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-football"></i>
                <code class="mr-2">f25d</code>
                <span>mdi-football</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-football-australian"></i>
                <code class="mr-2">f25e</code>
                <span>mdi-football-australian</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-football-helmet"></i>
                <code class="mr-2">f25f</code>
                <span>mdi-football-helmet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-align-center"></i>
                <code class="mr-2">f260</code>
                <span>mdi-format-align-center</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-align-justify"></i>
                <code class="mr-2">f261</code>
                <span>mdi-format-align-justify</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-align-left"></i>
                <code class="mr-2">f262</code>
                <span>mdi-format-align-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-align-right"></i>
                <code class="mr-2">f263</code>
                <span>mdi-format-align-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-annotation-plus"></i>
                <code class="mr-2">f646</code>
                <span>mdi-format-annotation-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-bold"></i>
                <code class="mr-2">f264</code>
                <span>mdi-format-bold</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-clear"></i>
                <code class="mr-2">f265</code>
                <span>mdi-format-clear</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-color-fill"></i>
                <code class="mr-2">f266</code>
                <span>mdi-format-color-fill</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-color-text"></i>
                <code class="mr-2">f69d</code>
                <span>mdi-format-color-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-float-center"></i>
                <code class="mr-2">f267</code>
                <span>mdi-format-float-center</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-float-left"></i>
                <code class="mr-2">f268</code>
                <span>mdi-format-float-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-float-none"></i>
                <code class="mr-2">f269</code>
                <span>mdi-format-float-none</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-float-right"></i>
                <code class="mr-2">f26a</code>
                <span>mdi-format-float-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-font"></i>
                <code class="mr-2">f6d5</code>
                <span>mdi-format-font</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-1"></i>
                <code class="mr-2">f26b</code>
                <span>mdi-format-header-1</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-2"></i>
                <code class="mr-2">f26c</code>
                <span>mdi-format-header-2</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-3"></i>
                <code class="mr-2">f26d</code>
                <span>mdi-format-header-3</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-4"></i>
                <code class="mr-2">f26e</code>
                <span>mdi-format-header-4</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-5"></i>
                <code class="mr-2">f26f</code>
                <span>mdi-format-header-5</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-6"></i>
                <code class="mr-2">f270</code>
                <span>mdi-format-header-6</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-decrease"></i>
                <code class="mr-2">f271</code>
                <span>mdi-format-header-decrease</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-equal"></i>
                <code class="mr-2">f272</code>
                <span>mdi-format-header-equal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-increase"></i>
                <code class="mr-2">f273</code>
                <span>mdi-format-header-increase</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-header-pound"></i>
                <code class="mr-2">f274</code>
                <span>mdi-format-header-pound</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-horizontal-align-center"></i>
                <code class="mr-2">f61e</code>
                <span>mdi-format-horizontal-align-center</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-horizontal-align-left"></i>
                <code class="mr-2">f61f</code>
                <span>mdi-format-horizontal-align-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-horizontal-align-right"></i>
                <code class="mr-2">f620</code>
                <span>mdi-format-horizontal-align-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-indent-decrease"></i>
                <code class="mr-2">f275</code>
                <span>mdi-format-indent-decrease</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-indent-increase"></i>
                <code class="mr-2">f276</code>
                <span>mdi-format-indent-increase</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-italic"></i>
                <code class="mr-2">f277</code>
                <span>mdi-format-italic</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-line-spacing"></i>
                <code class="mr-2">f278</code>
                <span>mdi-format-line-spacing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-line-style"></i>
                <code class="mr-2">f5c8</code>
                <span>mdi-format-line-style</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-line-weight"></i>
                <code class="mr-2">f5c9</code>
                <span>mdi-format-line-weight</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-list-bulleted"></i>
                <code class="mr-2">f279</code>
                <span>mdi-format-list-bulleted</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-list-bulleted-type"></i>
                <code class="mr-2">f27a</code>
                <span>mdi-format-list-bulleted-type</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-list-numbers"></i>
                <code class="mr-2">f27b</code>
                <span>mdi-format-list-numbers</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-page-break"></i>
                <code class="mr-2">f6d6</code>
                <span>mdi-format-page-break</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-paint"></i>
                <code class="mr-2">f27c</code>
                <span>mdi-format-paint</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-paragraph"></i>
                <code class="mr-2">f27d</code>
                <span>mdi-format-paragraph</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-pilcrow"></i>
                <code class="mr-2">f6d7</code>
                <span>mdi-format-pilcrow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-quote"></i>
                <code class="mr-2">f27e</code>
                <span>mdi-format-quote</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-rotate-90"></i>
                <code class="mr-2">f6a9</code>
                <span>mdi-format-rotate-90</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-section"></i>
                <code class="mr-2">f69e</code>
                <span>mdi-format-section</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-size"></i>
                <code class="mr-2">f27f</code>
                <span>mdi-format-size</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-strikethrough"></i>
                <code class="mr-2">f280</code>
                <span>mdi-format-strikethrough</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-strikethrough-variant"></i>
                <code class="mr-2">f281</code>
                <span>mdi-format-strikethrough-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-subscript"></i>
                <code class="mr-2">f282</code>
                <span>mdi-format-subscript</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-superscript"></i>
                <code class="mr-2">f283</code>
                <span>mdi-format-superscript</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-text"></i>
                <code class="mr-2">f284</code>
                <span>mdi-format-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-textdirection-l-to-r"></i>
                <code class="mr-2">f285</code>
                <span>mdi-format-textdirection-l-to-r</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-textdirection-r-to-l"></i>
                <code class="mr-2">f286</code>
                <span>mdi-format-textdirection-r-to-l</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-title"></i>
                <code class="mr-2">f5f4</code>
                <span>mdi-format-title</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-underline"></i>
                <code class="mr-2">f287</code>
                <span>mdi-format-underline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-vertical-align-bottom"></i>
                <code class="mr-2">f621</code>
                <span>mdi-format-vertical-align-bottom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-vertical-align-center"></i>
                <code class="mr-2">f622</code>
                <span>mdi-format-vertical-align-center</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-vertical-align-top"></i>
                <code class="mr-2">f623</code>
                <span>mdi-format-vertical-align-top</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-wrap-inline"></i>
                <code class="mr-2">f288</code>
                <span>mdi-format-wrap-inline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-wrap-square"></i>
                <code class="mr-2">f289</code>
                <span>mdi-format-wrap-square</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-wrap-tight"></i>
                <code class="mr-2">f28a</code>
                <span>mdi-format-wrap-tight</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-format-wrap-top-bottom"></i>
                <code class="mr-2">f28b</code>
                <span>mdi-format-wrap-top-bottom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-forum"></i>
                <code class="mr-2">f28c</code>
                <span>mdi-forum</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-forward"></i>
                <code class="mr-2">f28d</code>
                <span>mdi-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-foursquare"></i>
                <code class="mr-2">f28e</code>
                <span>mdi-foursquare</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fridge"></i>
                <code class="mr-2">f28f</code>
                <span>mdi-fridge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fridge-filled"></i>
                <code class="mr-2">f290</code>
                <span>mdi-fridge-filled</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fridge-filled-bottom"></i>
                <code class="mr-2">f291</code>
                <span>mdi-fridge-filled-bottom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fridge-filled-top"></i>
                <code class="mr-2">f292</code>
                <span>mdi-fridge-filled-top</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fullscreen"></i>
                <code class="mr-2">f293</code>
                <span>mdi-fullscreen</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-fullscreen-exit"></i>
                <code class="mr-2">f294</code>
                <span>mdi-fullscreen-exit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-function"></i>
                <code class="mr-2">f295</code>
                <span>mdi-function</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gamepad"></i>
                <code class="mr-2">f296</code>
                <span>mdi-gamepad</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gamepad-variant"></i>
                <code class="mr-2">f297</code>
                <span>mdi-gamepad-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-garage"></i>
                <code class="mr-2">f6d8</code>
                <span>mdi-garage</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-garage-open"></i>
                <code class="mr-2">f6d9</code>
                <span>mdi-garage-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gas-cylinder"></i>
                <code class="mr-2">f647</code>
                <span>mdi-gas-cylinder</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gas-station"></i>
                <code class="mr-2">f298</code>
                <span>mdi-gas-station</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gate"></i>
                <code class="mr-2">f299</code>
                <span>mdi-gate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gauge"></i>
                <code class="mr-2">f29a</code>
                <span>mdi-gauge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gavel"></i>
                <code class="mr-2">f29b</code>
                <span>mdi-gavel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gender-female"></i>
                <code class="mr-2">f29c</code>
                <span>mdi-gender-female</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gender-male"></i>
                <code class="mr-2">f29d</code>
                <span>mdi-gender-male</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gender-male-female"></i>
                <code class="mr-2">f29e</code>
                <span>mdi-gender-male-female</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gender-transgender"></i>
                <code class="mr-2">f29f</code>
                <span>mdi-gender-transgender</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ghost"></i>
                <code class="mr-2">f2a0</code>
                <span>mdi-ghost</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gift"></i>
                <code class="mr-2">f2a1</code>
                <span>mdi-gift</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-git"></i>
                <code class="mr-2">f2a2</code>
                <span>mdi-git</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-github-box"></i>
                <code class="mr-2">f2a3</code>
                <span>mdi-github-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-github-circle"></i>
                <code class="mr-2">f2a4</code>
                <span>mdi-github-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-github-face"></i>
                <code class="mr-2">f6da</code>
                <span>mdi-github-face</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glass-flute"></i>
                <code class="mr-2">f2a5</code>
                <span>mdi-glass-flute</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glass-mug"></i>
                <code class="mr-2">f2a6</code>
                <span>mdi-glass-mug</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glass-stange"></i>
                <code class="mr-2">f2a7</code>
                <span>mdi-glass-stange</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glass-tulip"></i>
                <code class="mr-2">f2a8</code>
                <span>mdi-glass-tulip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glassdoor"></i>
                <code class="mr-2">f2a9</code>
                <span>mdi-glassdoor</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-glasses"></i>
                <code class="mr-2">f2aa</code>
                <span>mdi-glasses</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gmail"></i>
                <code class="mr-2">f2ab</code>
                <span>mdi-gmail</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gnome"></i>
                <code class="mr-2">f2ac</code>
                <span>mdi-gnome</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gondola"></i>
                <code class="mr-2">f685</code>
                <span>mdi-gondola</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google"></i>
                <code class="mr-2">f2ad</code>
                <span>mdi-google</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-cardboard"></i>
                <code class="mr-2">f2ae</code>
                <span>mdi-google-cardboard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-chrome"></i>
                <code class="mr-2">f2af</code>
                <span>mdi-google-chrome</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-circles"></i>
                <code class="mr-2">f2b0</code>
                <span>mdi-google-circles</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-circles-communities"></i>
                <code class="mr-2">f2b1</code>
                <span>mdi-google-circles-communities</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-circles-extended"></i>
                <code class="mr-2">f2b2</code>
                <span>mdi-google-circles-extended</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-circles-group"></i>
                <code class="mr-2">f2b3</code>
                <span>mdi-google-circles-group</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-controller"></i>
                <code class="mr-2">f2b4</code>
                <span>mdi-google-controller</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-controller-off"></i>
                <code class="mr-2">f2b5</code>
                <span>mdi-google-controller-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-drive"></i>
                <code class="mr-2">f2b6</code>
                <span>mdi-google-drive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-earth"></i>
                <code class="mr-2">f2b7</code>
                <span>mdi-google-earth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-glass"></i>
                <code class="mr-2">f2b8</code>
                <span>mdi-google-glass</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-keep"></i>
                <code class="mr-2">f6db</code>
                <span>mdi-google-keep</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-maps"></i>
                <code class="mr-2">f5f5</code>
                <span>mdi-google-maps</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-nearby"></i>
                <code class="mr-2">f2b9</code>
                <span>mdi-google-nearby</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-pages"></i>
                <code class="mr-2">f2ba</code>
                <span>mdi-google-pages</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-photos"></i>
                <code class="mr-2">f6dc</code>
                <span>mdi-google-photos</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-physical-web"></i>
                <code class="mr-2">f2bb</code>
                <span>mdi-google-physical-web</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-play"></i>
                <code class="mr-2">f2bc</code>
                <span>mdi-google-play</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-plus"></i>
                <code class="mr-2">f2bd</code>
                <span>mdi-google-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-plus-box"></i>
                <code class="mr-2">f2be</code>
                <span>mdi-google-plus-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-translate"></i>
                <code class="mr-2">f2bf</code>
                <span>mdi-google-translate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-google-wallet"></i>
                <code class="mr-2">f2c0</code>
                <span>mdi-google-wallet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-gradient"></i>
                <code class="mr-2">f69f</code>
                <span>mdi-gradient</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-grease-pencil"></i>
                <code class="mr-2">f648</code>
                <span>mdi-grease-pencil</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-grid"></i>
                <code class="mr-2">f2c1</code>
                <span>mdi-grid</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-grid-off"></i>
                <code class="mr-2">f2c2</code>
                <span>mdi-grid-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-group"></i>
                <code class="mr-2">f2c3</code>
                <span>mdi-group</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-guitar-electric"></i>
                <code class="mr-2">f2c4</code>
                <span>mdi-guitar-electric</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-guitar-pick"></i>
                <code class="mr-2">f2c5</code>
                <span>mdi-guitar-pick</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-guitar-pick-outline"></i>
                <code class="mr-2">f2c6</code>
                <span>mdi-guitar-pick-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hackernews"></i>
                <code class="mr-2">f624</code>
                <span>mdi-hackernews</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hamburger"></i>
                <code class="mr-2">f684</code>
                <span>mdi-hamburger</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hand-pointing-right"></i>
                <code class="mr-2">f2c7</code>
                <span>mdi-hand-pointing-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hanger"></i>
                <code class="mr-2">f2c8</code>
                <span>mdi-hanger</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hangouts"></i>
                <code class="mr-2">f2c9</code>
                <span>mdi-hangouts</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-harddisk"></i>
                <code class="mr-2">f2ca</code>
                <span>mdi-harddisk</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headphones"></i>
                <code class="mr-2">f2cb</code>
                <span>mdi-headphones</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headphones-box"></i>
                <code class="mr-2">f2cc</code>
                <span>mdi-headphones-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headphones-settings"></i>
                <code class="mr-2">f2cd</code>
                <span>mdi-headphones-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headset"></i>
                <code class="mr-2">f2ce</code>
                <span>mdi-headset</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headset-dock"></i>
                <code class="mr-2">f2cf</code>
                <span>mdi-headset-dock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-headset-off"></i>
                <code class="mr-2">f2d0</code>
                <span>mdi-headset-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart"></i>
                <code class="mr-2">f2d1</code>
                <span>mdi-heart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-box"></i>
                <code class="mr-2">f2d2</code>
                <span>mdi-heart-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-box-outline"></i>
                <code class="mr-2">f2d3</code>
                <span>mdi-heart-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-broken"></i>
                <code class="mr-2">f2d4</code>
                <span>mdi-heart-broken</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-half-outline"></i>
                <code class="mr-2">f6dd</code>
                <span>mdi-heart-half-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-half-part"></i>
                <code class="mr-2">f6de</code>
                <span>mdi-heart-half-part</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-half-part-outline"></i>
                <code class="mr-2">f6df</code>
                <span>mdi-heart-half-part-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-outline"></i>
                <code class="mr-2">f2d5</code>
                <span>mdi-heart-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-heart-pulse"></i>
                <code class="mr-2">f5f6</code>
                <span>mdi-heart-pulse</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-help"></i>
                <code class="mr-2">f2d6</code>
                <span>mdi-help</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-help-circle"></i>
                <code class="mr-2">f2d7</code>
                <span>mdi-help-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-help-circle-outline"></i>
                <code class="mr-2">f625</code>
                <span>mdi-help-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hexagon"></i>
                <code class="mr-2">f2d8</code>
                <span>mdi-hexagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hexagon-multiple"></i>
                <code class="mr-2">f6e0</code>
                <span>mdi-hexagon-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hexagon-outline"></i>
                <code class="mr-2">f2d9</code>
                <span>mdi-hexagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-highway"></i>
                <code class="mr-2">f5f7</code>
                <span>mdi-highway</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-history"></i>
                <code class="mr-2">f2da</code>
                <span>mdi-history</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hololens"></i>
                <code class="mr-2">f2db</code>
                <span>mdi-hololens</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-home"></i>
                <code class="mr-2">f2dc</code>
                <span>mdi-home</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-home-map-marker"></i>
                <code class="mr-2">f5f8</code>
                <span>mdi-home-map-marker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-home-modern"></i>
                <code class="mr-2">f2dd</code>
                <span>mdi-home-modern</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-home-outline"></i>
                <code class="mr-2">f6a0</code>
                <span>mdi-home-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-home-variant"></i>
                <code class="mr-2">f2de</code>
                <span>mdi-home-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hook"></i>
                <code class="mr-2">f6e1</code>
                <span>mdi-hook</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hook-off"></i>
                <code class="mr-2">f6e2</code>
                <span>mdi-hook-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hops"></i>
                <code class="mr-2">f2df</code>
                <span>mdi-hops</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hospital"></i>
                <code class="mr-2">f2e0</code>
                <span>mdi-hospital</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hospital-building"></i>
                <code class="mr-2">f2e1</code>
                <span>mdi-hospital-building</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hospital-marker"></i>
                <code class="mr-2">f2e2</code>
                <span>mdi-hospital-marker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-hotel"></i>
                <code class="mr-2">f2e3</code>
                <span>mdi-hotel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-houzz"></i>
                <code class="mr-2">f2e4</code>
                <span>mdi-houzz</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-houzz-box"></i>
                <code class="mr-2">f2e5</code>
                <span>mdi-houzz-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human"></i>
                <code class="mr-2">f2e6</code>
                <span>mdi-human</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-child"></i>
                <code class="mr-2">f2e7</code>
                <span>mdi-human-child</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-female"></i>
                <code class="mr-2">f649</code>
                <span>mdi-human-female</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-greeting"></i>
                <code class="mr-2">f64a</code>
                <span>mdi-human-greeting</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-handsdown"></i>
                <code class="mr-2">f64b</code>
                <span>mdi-human-handsdown</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-handsup"></i>
                <code class="mr-2">f64c</code>
                <span>mdi-human-handsup</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-male"></i>
                <code class="mr-2">f64d</code>
                <span>mdi-human-male</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-male-female"></i>
                <code class="mr-2">f2e8</code>
                <span>mdi-human-male-female</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-human-pregnant"></i>
                <code class="mr-2">f5cf</code>
                <span>mdi-human-pregnant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image"></i>
                <code class="mr-2">f2e9</code>
                <span>mdi-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-album"></i>
                <code class="mr-2">f2ea</code>
                <span>mdi-image-album</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-area"></i>
                <code class="mr-2">f2eb</code>
                <span>mdi-image-area</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-area-close"></i>
                <code class="mr-2">f2ec</code>
                <span>mdi-image-area-close</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-broken"></i>
                <code class="mr-2">f2ed</code>
                <span>mdi-image-broken</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-broken-variant"></i>
                <code class="mr-2">f2ee</code>
                <span>mdi-image-broken-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter"></i>
                <code class="mr-2">f2ef</code>
                <span>mdi-image-filter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-black-white"></i>
                <code class="mr-2">f2f0</code>
                <span>mdi-image-filter-black-white</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-center-focus"></i>
                <code class="mr-2">f2f1</code>
                <span>mdi-image-filter-center-focus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-center-focus-weak"></i>
                <code class="mr-2">f2f2</code>
                <span>mdi-image-filter-center-focus-weak</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-drama"></i>
                <code class="mr-2">f2f3</code>
                <span>mdi-image-filter-drama</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-frames"></i>
                <code class="mr-2">f2f4</code>
                <span>mdi-image-filter-frames</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-hdr"></i>
                <code class="mr-2">f2f5</code>
                <span>mdi-image-filter-hdr</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-none"></i>
                <code class="mr-2">f2f6</code>
                <span>mdi-image-filter-none</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-tilt-shift"></i>
                <code class="mr-2">f2f7</code>
                <span>mdi-image-filter-tilt-shift</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-filter-vintage"></i>
                <code class="mr-2">f2f8</code>
                <span>mdi-image-filter-vintage</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-image-multiple"></i>
                <code class="mr-2">f2f9</code>
                <span>mdi-image-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-import"></i>
                <code class="mr-2">f2fa</code>
                <span>mdi-import</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-inbox"></i>
                <code class="mr-2">f686</code>
                <span>mdi-inbox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-inbox-arrow-down"></i>
                <code class="mr-2">f2fb</code>
                <span>mdi-inbox-arrow-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-inbox-arrow-up"></i>
                <code class="mr-2">f3d1</code>
                <span>mdi-inbox-arrow-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-incognito"></i>
                <code class="mr-2">f5f9</code>
                <span>mdi-incognito</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-infinity"></i>
                <code class="mr-2">f6e3</code>
                <span>mdi-infinity</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-information"></i>
                <code class="mr-2">f2fc</code>
                <span>mdi-information</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-information-outline"></i>
                <code class="mr-2">f2fd</code>
                <span>mdi-information-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-information-variant"></i>
                <code class="mr-2">f64e</code>
                <span>mdi-information-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-instagram"></i>
                <code class="mr-2">f2fe</code>
                <span>mdi-instagram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-instapaper"></i>
                <code class="mr-2">f2ff</code>
                <span>mdi-instapaper</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-internet-explorer"></i>
                <code class="mr-2">f300</code>
                <span>mdi-internet-explorer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-invert-colors"></i>
                <code class="mr-2">f301</code>
                <span>mdi-invert-colors</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-itunes"></i>
                <code class="mr-2">f676</code>
                <span>mdi-itunes</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-jeepney"></i>
                <code class="mr-2">f302</code>
                <span>mdi-jeepney</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-jira"></i>
                <code class="mr-2">f303</code>
                <span>mdi-jira</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-jsfiddle"></i>
                <code class="mr-2">f304</code>
                <span>mdi-jsfiddle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-json"></i>
                <code class="mr-2">f626</code>
                <span>mdi-json</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keg"></i>
                <code class="mr-2">f305</code>
                <span>mdi-keg</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-kettle"></i>
                <code class="mr-2">f5fa</code>
                <span>mdi-kettle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key"></i>
                <code class="mr-2">f306</code>
                <span>mdi-key</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key-change"></i>
                <code class="mr-2">f307</code>
                <span>mdi-key-change</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key-minus"></i>
                <code class="mr-2">f308</code>
                <span>mdi-key-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key-plus"></i>
                <code class="mr-2">f309</code>
                <span>mdi-key-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key-remove"></i>
                <code class="mr-2">f30a</code>
                <span>mdi-key-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-key-variant"></i>
                <code class="mr-2">f30b</code>
                <span>mdi-key-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard"></i>
                <code class="mr-2">f30c</code>
                <span>mdi-keyboard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-backspace"></i>
                <code class="mr-2">f30d</code>
                <span>mdi-keyboard-backspace</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-caps"></i>
                <code class="mr-2">f30e</code>
                <span>mdi-keyboard-caps</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-close"></i>
                <code class="mr-2">f30f</code>
                <span>mdi-keyboard-close</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-off"></i>
                <code class="mr-2">f310</code>
                <span>mdi-keyboard-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-return"></i>
                <code class="mr-2">f311</code>
                <span>mdi-keyboard-return</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-tab"></i>
                <code class="mr-2">f312</code>
                <span>mdi-keyboard-tab</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-keyboard-variant"></i>
                <code class="mr-2">f313</code>
                <span>mdi-keyboard-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-kodi"></i>
                <code class="mr-2">f314</code>
                <span>mdi-kodi</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-label"></i>
                <code class="mr-2">f315</code>
                <span>mdi-label</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-label-outline"></i>
                <code class="mr-2">f316</code>
                <span>mdi-label-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lambda"></i>
                <code class="mr-2">f627</code>
                <span>mdi-lambda</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lamp"></i>
                <code class="mr-2">f6b4</code>
                <span>mdi-lamp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lan"></i>
                <code class="mr-2">f317</code>
                <span>mdi-lan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lan-connect"></i>
                <code class="mr-2">f318</code>
                <span>mdi-lan-connect</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lan-disconnect"></i>
                <code class="mr-2">f319</code>
                <span>mdi-lan-disconnect</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lan-pending"></i>
                <code class="mr-2">f31a</code>
                <span>mdi-lan-pending</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-c"></i>
                <code class="mr-2">f671</code>
                <span>mdi-language-c</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-cpp"></i>
                <code class="mr-2">f672</code>
                <span>mdi-language-cpp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-csharp"></i>
                <code class="mr-2">f31b</code>
                <span>mdi-language-csharp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-css3"></i>
                <code class="mr-2">f31c</code>
                <span>mdi-language-css3</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-html5"></i>
                <code class="mr-2">f31d</code>
                <span>mdi-language-html5</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-javascript"></i>
                <code class="mr-2">f31e</code>
                <span>mdi-language-javascript</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-php"></i>
                <code class="mr-2">f31f</code>
                <span>mdi-language-php</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-python"></i>
                <code class="mr-2">f320</code>
                <span>mdi-language-python</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-python-text"></i>
                <code class="mr-2">f321</code>
                <span>mdi-language-python-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-swift"></i>
                <code class="mr-2">f6e4</code>
                <span>mdi-language-swift</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-language-typescript"></i>
                <code class="mr-2">f6e5</code>
                <span>mdi-language-typescript</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-laptop"></i>
                <code class="mr-2">f322</code>
                <span>mdi-laptop</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-laptop-chromebook"></i>
                <code class="mr-2">f323</code>
                <span>mdi-laptop-chromebook</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-laptop-mac"></i>
                <code class="mr-2">f324</code>
                <span>mdi-laptop-mac</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-laptop-off"></i>
                <code class="mr-2">f6e6</code>
                <span>mdi-laptop-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-laptop-windows"></i>
                <code class="mr-2">f325</code>
                <span>mdi-laptop-windows</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lastfm"></i>
                <code class="mr-2">f326</code>
                <span>mdi-lastfm</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-launch"></i>
                <code class="mr-2">f327</code>
                <span>mdi-launch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-layers"></i>
                <code class="mr-2">f328</code>
                <span>mdi-layers</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-layers-off"></i>
                <code class="mr-2">f329</code>
                <span>mdi-layers-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lead-pencil"></i>
                <code class="mr-2">f64f</code>
                <span>mdi-lead-pencil</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-leaf"></i>
                <code class="mr-2">f32a</code>
                <span>mdi-leaf</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-off"></i>
                <code class="mr-2">f32b</code>
                <span>mdi-led-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-on"></i>
                <code class="mr-2">f32c</code>
                <span>mdi-led-on</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-outline"></i>
                <code class="mr-2">f32d</code>
                <span>mdi-led-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-variant-off"></i>
                <code class="mr-2">f32e</code>
                <span>mdi-led-variant-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-variant-on"></i>
                <code class="mr-2">f32f</code>
                <span>mdi-led-variant-on</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-led-variant-outline"></i>
                <code class="mr-2">f330</code>
                <span>mdi-led-variant-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-library"></i>
                <code class="mr-2">f331</code>
                <span>mdi-library</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-library-books"></i>
                <code class="mr-2">f332</code>
                <span>mdi-library-books</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-library-music"></i>
                <code class="mr-2">f333</code>
                <span>mdi-library-music</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-library-plus"></i>
                <code class="mr-2">f334</code>
                <span>mdi-library-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lightbulb"></i>
                <code class="mr-2">f335</code>
                <span>mdi-lightbulb</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lightbulb-on"></i>
                <code class="mr-2">f6e7</code>
                <span>mdi-lightbulb-on</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lightbulb-on-outline"></i>
                <code class="mr-2">f6e8</code>
                <span>mdi-lightbulb-on-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lightbulb-outline"></i>
                <code class="mr-2">f336</code>
                <span>mdi-lightbulb-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-link"></i>
                <code class="mr-2">f337</code>
                <span>mdi-link</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-link-off"></i>
                <code class="mr-2">f338</code>
                <span>mdi-link-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-link-variant"></i>
                <code class="mr-2">f339</code>
                <span>mdi-link-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-link-variant-off"></i>
                <code class="mr-2">f33a</code>
                <span>mdi-link-variant-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-linkedin"></i>
                <code class="mr-2">f33b</code>
                <span>mdi-linkedin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-linkedin-box"></i>
                <code class="mr-2">f33c</code>
                <span>mdi-linkedin-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-linux"></i>
                <code class="mr-2">f33d</code>
                <span>mdi-linux</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock"></i>
                <code class="mr-2">f33e</code>
                <span>mdi-lock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock-open"></i>
                <code class="mr-2">f33f</code>
                <span>mdi-lock-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock-open-outline"></i>
                <code class="mr-2">f340</code>
                <span>mdi-lock-open-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock-outline"></i>
                <code class="mr-2">f341</code>
                <span>mdi-lock-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock-pattern"></i>
                <code class="mr-2">f6e9</code>
                <span>mdi-lock-pattern</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lock-plus"></i>
                <code class="mr-2">f5fb</code>
                <span>mdi-lock-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-login"></i>
                <code class="mr-2">f342</code>
                <span>mdi-login</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-login-variant"></i>
                <code class="mr-2">f5fc</code>
                <span>mdi-login-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-logout"></i>
                <code class="mr-2">f343</code>
                <span>mdi-logout</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-logout-variant"></i>
                <code class="mr-2">f5fd</code>
                <span>mdi-logout-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-looks"></i>
                <code class="mr-2">f344</code>
                <span>mdi-looks</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-loop"></i>
                <code class="mr-2">f6ea</code>
                <span>mdi-loop</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-loupe"></i>
                <code class="mr-2">f345</code>
                <span>mdi-loupe</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-lumx"></i>
                <code class="mr-2">f346</code>
                <span>mdi-lumx</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnet"></i>
                <code class="mr-2">f347</code>
                <span>mdi-magnet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnet-on"></i>
                <code class="mr-2">f348</code>
                <span>mdi-magnet-on</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnify"></i>
                <code class="mr-2">f349</code>
                <span>mdi-magnify</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnify-minus"></i>
                <code class="mr-2">f34a</code>
                <span>mdi-magnify-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnify-minus-outline"></i>
                <code class="mr-2">f6eb</code>
                <span>mdi-magnify-minus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnify-plus"></i>
                <code class="mr-2">f34b</code>
                <span>mdi-magnify-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-magnify-plus-outline"></i>
                <code class="mr-2">f6ec</code>
                <span>mdi-magnify-plus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mail-ru"></i>
                <code class="mr-2">f34c</code>
                <span>mdi-mail-ru</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mailbox"></i>
                <code class="mr-2">f6ed</code>
                <span>mdi-mailbox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map"></i>
                <code class="mr-2">f34d</code>
                <span>mdi-map</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker"></i>
                <code class="mr-2">f34e</code>
                <span>mdi-map-marker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-circle"></i>
                <code class="mr-2">f34f</code>
                <span>mdi-map-marker-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-minus"></i>
                <code class="mr-2">f650</code>
                <span>mdi-map-marker-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-multiple"></i>
                <code class="mr-2">f350</code>
                <span>mdi-map-marker-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-off"></i>
                <code class="mr-2">f351</code>
                <span>mdi-map-marker-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-plus"></i>
                <code class="mr-2">f651</code>
                <span>mdi-map-marker-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-map-marker-radius"></i>
                <code class="mr-2">f352</code>
                <span>mdi-map-marker-radius</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-margin"></i>
                <code class="mr-2">f353</code>
                <span>mdi-margin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-markdown"></i>
                <code class="mr-2">f354</code>
                <span>mdi-markdown</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-marker"></i>
                <code class="mr-2">f652</code>
                <span>mdi-marker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-marker-check"></i>
                <code class="mr-2">f355</code>
                <span>mdi-marker-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-martini"></i>
                <code class="mr-2">f356</code>
                <span>mdi-martini</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-material-ui"></i>
                <code class="mr-2">f357</code>
                <span>mdi-material-ui</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-math-compass"></i>
                <code class="mr-2">f358</code>
                <span>mdi-math-compass</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-matrix"></i>
                <code class="mr-2">f628</code>
                <span>mdi-matrix</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-maxcdn"></i>
                <code class="mr-2">f359</code>
                <span>mdi-maxcdn</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-medical-bag"></i>
                <code class="mr-2">f6ee</code>
                <span>mdi-medical-bag</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-medium"></i>
                <code class="mr-2">f35a</code>
                <span>mdi-medium</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-memory"></i>
                <code class="mr-2">f35b</code>
                <span>mdi-memory</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu"></i>
                <code class="mr-2">f35c</code>
                <span>mdi-menu</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-down"></i>
                <code class="mr-2">f35d</code>
                <span>mdi-menu-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-down-outline"></i>
                <code class="mr-2">f6b5</code>
                <span>mdi-menu-down-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-left"></i>
                <code class="mr-2">f35e</code>
                <span>mdi-menu-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-right"></i>
                <code class="mr-2">f35f</code>
                <span>mdi-menu-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-up"></i>
                <code class="mr-2">f360</code>
                <span>mdi-menu-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-menu-up-outline"></i>
                <code class="mr-2">f6b6</code>
                <span>mdi-menu-up-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message"></i>
                <code class="mr-2">f361</code>
                <span>mdi-message</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-alert"></i>
                <code class="mr-2">f362</code>
                <span>mdi-message-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-bulleted"></i>
                <code class="mr-2">f6a1</code>
                <span>mdi-message-bulleted</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-bulleted-off"></i>
                <code class="mr-2">f6a2</code>
                <span>mdi-message-bulleted-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-draw"></i>
                <code class="mr-2">f363</code>
                <span>mdi-message-draw</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-image"></i>
                <code class="mr-2">f364</code>
                <span>mdi-message-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-outline"></i>
                <code class="mr-2">f365</code>
                <span>mdi-message-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-plus"></i>
                <code class="mr-2">f653</code>
                <span>mdi-message-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-processing"></i>
                <code class="mr-2">f366</code>
                <span>mdi-message-processing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-reply"></i>
                <code class="mr-2">f367</code>
                <span>mdi-message-reply</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-reply-text"></i>
                <code class="mr-2">f368</code>
                <span>mdi-message-reply-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-settings"></i>
                <code class="mr-2">f6ef</code>
                <span>mdi-message-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-settings-variant"></i>
                <code class="mr-2">f6f0</code>
                <span>mdi-message-settings-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-text"></i>
                <code class="mr-2">f369</code>
                <span>mdi-message-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-text-outline"></i>
                <code class="mr-2">f36a</code>
                <span>mdi-message-text-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-message-video"></i>
                <code class="mr-2">f36b</code>
                <span>mdi-message-video</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-meteor"></i>
                <code class="mr-2">f629</code>
                <span>mdi-meteor</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone"></i>
                <code class="mr-2">f36c</code>
                <span>mdi-microphone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone-off"></i>
                <code class="mr-2">f36d</code>
                <span>mdi-microphone-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone-outline"></i>
                <code class="mr-2">f36e</code>
                <span>mdi-microphone-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone-settings"></i>
                <code class="mr-2">f36f</code>
                <span>mdi-microphone-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone-variant"></i>
                <code class="mr-2">f370</code>
                <span>mdi-microphone-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microphone-variant-off"></i>
                <code class="mr-2">f371</code>
                <span>mdi-microphone-variant-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microscope"></i>
                <code class="mr-2">f654</code>
                <span>mdi-microscope</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-microsoft"></i>
                <code class="mr-2">f372</code>
                <span>mdi-microsoft</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minecraft"></i>
                <code class="mr-2">f373</code>
                <span>mdi-minecraft</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus"></i>
                <code class="mr-2">f374</code>
                <span>mdi-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus-box"></i>
                <code class="mr-2">f375</code>
                <span>mdi-minus-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus-box-outline"></i>
                <code class="mr-2">f6f1</code>
                <span>mdi-minus-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus-circle"></i>
                <code class="mr-2">f376</code>
                <span>mdi-minus-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus-circle-outline"></i>
                <code class="mr-2">f377</code>
                <span>mdi-minus-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-minus-network"></i>
                <code class="mr-2">f378</code>
                <span>mdi-minus-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mixcloud"></i>
                <code class="mr-2">f62a</code>
                <span>mdi-mixcloud</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-monitor"></i>
                <code class="mr-2">f379</code>
                <span>mdi-monitor</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-monitor-multiple"></i>
                <code class="mr-2">f37a</code>
                <span>mdi-monitor-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-more"></i>
                <code class="mr-2">f37b</code>
                <span>mdi-more</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-motorbike"></i>
                <code class="mr-2">f37c</code>
                <span>mdi-motorbike</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mouse"></i>
                <code class="mr-2">f37d</code>
                <span>mdi-mouse</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mouse-off"></i>
                <code class="mr-2">f37e</code>
                <span>mdi-mouse-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mouse-variant"></i>
                <code class="mr-2">f37f</code>
                <span>mdi-mouse-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-mouse-variant-off"></i>
                <code class="mr-2">f380</code>
                <span>mdi-mouse-variant-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-move-resize"></i>
                <code class="mr-2">f655</code>
                <span>mdi-move-resize</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-move-resize-variant"></i>
                <code class="mr-2">f656</code>
                <span>mdi-move-resize-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-movie"></i>
                <code class="mr-2">f381</code>
                <span>mdi-movie</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-multiplication"></i>
                <code class="mr-2">f382</code>
                <span>mdi-multiplication</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-multiplication-box"></i>
                <code class="mr-2">f383</code>
                <span>mdi-multiplication-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-box"></i>
                <code class="mr-2">f384</code>
                <span>mdi-music-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-box-outline"></i>
                <code class="mr-2">f385</code>
                <span>mdi-music-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-circle"></i>
                <code class="mr-2">f386</code>
                <span>mdi-music-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note"></i>
                <code class="mr-2">f387</code>
                <span>mdi-music-note</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-bluetooth"></i>
                <code class="mr-2">f5fe</code>
                <span>mdi-music-note-bluetooth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-bluetooth-off"></i>
                <code class="mr-2">f5ff</code>
                <span>mdi-music-note-bluetooth-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-eighth"></i>
                <code class="mr-2">f388</code>
                <span>mdi-music-note-eighth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-half"></i>
                <code class="mr-2">f389</code>
                <span>mdi-music-note-half</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-off"></i>
                <code class="mr-2">f38a</code>
                <span>mdi-music-note-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-quarter"></i>
                <code class="mr-2">f38b</code>
                <span>mdi-music-note-quarter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-sixteenth"></i>
                <code class="mr-2">f38c</code>
                <span>mdi-music-note-sixteenth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-music-note-whole"></i>
                <code class="mr-2">f38d</code>
                <span>mdi-music-note-whole</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nature"></i>
                <code class="mr-2">f38e</code>
                <span>mdi-nature</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nature-people"></i>
                <code class="mr-2">f38f</code>
                <span>mdi-nature-people</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-navigation"></i>
                <code class="mr-2">f390</code>
                <span>mdi-navigation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-near-me"></i>
                <code class="mr-2">f5cd</code>
                <span>mdi-near-me</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-needle"></i>
                <code class="mr-2">f391</code>
                <span>mdi-needle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nest-protect"></i>
                <code class="mr-2">f392</code>
                <span>mdi-nest-protect</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nest-thermostat"></i>
                <code class="mr-2">f393</code>
                <span>mdi-nest-thermostat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-network"></i>
                <code class="mr-2">f6f2</code>
                <span>mdi-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-network-download"></i>
                <code class="mr-2">f6f3</code>
                <span>mdi-network-download</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-network-question"></i>
                <code class="mr-2">f6f4</code>
                <span>mdi-network-question</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-network-upload"></i>
                <code class="mr-2">f6f5</code>
                <span>mdi-network-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-new-box"></i>
                <code class="mr-2">f394</code>
                <span>mdi-new-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-newspaper"></i>
                <code class="mr-2">f395</code>
                <span>mdi-newspaper</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nfc"></i>
                <code class="mr-2">f396</code>
                <span>mdi-nfc</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nfc-tap"></i>
                <code class="mr-2">f397</code>
                <span>mdi-nfc-tap</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nfc-variant"></i>
                <code class="mr-2">f398</code>
                <span>mdi-nfc-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nodejs"></i>
                <code class="mr-2">f399</code>
                <span>mdi-nodejs</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note"></i>
                <code class="mr-2">f39a</code>
                <span>mdi-note</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-multiple"></i>
                <code class="mr-2">f6b7</code>
                <span>mdi-note-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-multiple-outline"></i>
                <code class="mr-2">f6b8</code>
                <span>mdi-note-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-outline"></i>
                <code class="mr-2">f39b</code>
                <span>mdi-note-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-plus"></i>
                <code class="mr-2">f39c</code>
                <span>mdi-note-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-plus-outline"></i>
                <code class="mr-2">f39d</code>
                <span>mdi-note-plus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-note-text"></i>
                <code class="mr-2">f39e</code>
                <span>mdi-note-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-notification-clear-all"></i>
                <code class="mr-2">f39f</code>
                <span>mdi-notification-clear-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-npm"></i>
                <code class="mr-2">f6f6</code>
                <span>mdi-npm</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nuke"></i>
                <code class="mr-2">f6a3</code>
                <span>mdi-nuke</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric"></i>
                <code class="mr-2">f3a0</code>
                <span>mdi-numeric</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-0-box"></i>
                <code class="mr-2">f3a1</code>
                <span>mdi-numeric-0-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-0-box-multiple-outline"></i>
                <code class="mr-2">f3a2</code>
                <span>mdi-numeric-0-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-0-box-outline"></i>
                <code class="mr-2">f3a3</code>
                <span>mdi-numeric-0-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-1-box"></i>
                <code class="mr-2">f3a4</code>
                <span>mdi-numeric-1-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-1-box-multiple-outline"></i>
                <code class="mr-2">f3a5</code>
                <span>mdi-numeric-1-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-1-box-outline"></i>
                <code class="mr-2">f3a6</code>
                <span>mdi-numeric-1-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-2-box"></i>
                <code class="mr-2">f3a7</code>
                <span>mdi-numeric-2-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-2-box-multiple-outline"></i>
                <code class="mr-2">f3a8</code>
                <span>mdi-numeric-2-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-2-box-outline"></i>
                <code class="mr-2">f3a9</code>
                <span>mdi-numeric-2-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-3-box"></i>
                <code class="mr-2">f3aa</code>
                <span>mdi-numeric-3-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-3-box-multiple-outline"></i>
                <code class="mr-2">f3ab</code>
                <span>mdi-numeric-3-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-3-box-outline"></i>
                <code class="mr-2">f3ac</code>
                <span>mdi-numeric-3-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-4-box"></i>
                <code class="mr-2">f3ad</code>
                <span>mdi-numeric-4-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-4-box-multiple-outline"></i>
                <code class="mr-2">f3ae</code>
                <span>mdi-numeric-4-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-4-box-outline"></i>
                <code class="mr-2">f3af</code>
                <span>mdi-numeric-4-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-5-box"></i>
                <code class="mr-2">f3b0</code>
                <span>mdi-numeric-5-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-5-box-multiple-outline"></i>
                <code class="mr-2">f3b1</code>
                <span>mdi-numeric-5-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-5-box-outline"></i>
                <code class="mr-2">f3b2</code>
                <span>mdi-numeric-5-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-6-box"></i>
                <code class="mr-2">f3b3</code>
                <span>mdi-numeric-6-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-6-box-multiple-outline"></i>
                <code class="mr-2">f3b4</code>
                <span>mdi-numeric-6-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-6-box-outline"></i>
                <code class="mr-2">f3b5</code>
                <span>mdi-numeric-6-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-7-box"></i>
                <code class="mr-2">f3b6</code>
                <span>mdi-numeric-7-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-7-box-multiple-outline"></i>
                <code class="mr-2">f3b7</code>
                <span>mdi-numeric-7-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-7-box-outline"></i>
                <code class="mr-2">f3b8</code>
                <span>mdi-numeric-7-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-8-box"></i>
                <code class="mr-2">f3b9</code>
                <span>mdi-numeric-8-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-8-box-multiple-outline"></i>
                <code class="mr-2">f3ba</code>
                <span>mdi-numeric-8-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-8-box-outline"></i>
                <code class="mr-2">f3bb</code>
                <span>mdi-numeric-8-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-box"></i>
                <code class="mr-2">f3bc</code>
                <span>mdi-numeric-9-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-box-multiple-outline"></i>
                <code class="mr-2">f3bd</code>
                <span>mdi-numeric-9-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-box-outline"></i>
                <code class="mr-2">f3be</code>
                <span>mdi-numeric-9-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-plus-box"></i>
                <code class="mr-2">f3bf</code>
                <span>mdi-numeric-9-plus-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-plus-box-multiple-outline"></i>
                <code class="mr-2">f3c0</code>
                <span>mdi-numeric-9-plus-box-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-numeric-9-plus-box-outline"></i>
                <code class="mr-2">f3c1</code>
                <span>mdi-numeric-9-plus-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nut"></i>
                <code class="mr-2">f6f7</code>
                <span>mdi-nut</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-nutrition"></i>
                <code class="mr-2">f3c2</code>
                <span>mdi-nutrition</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-oar"></i>
                <code class="mr-2">f67b</code>
                <span>mdi-oar</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-octagon"></i>
                <code class="mr-2">f3c3</code>
                <span>mdi-octagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-octagon-outline"></i>
                <code class="mr-2">f3c4</code>
                <span>mdi-octagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-octagram"></i>
                <code class="mr-2">f6f8</code>
                <span>mdi-octagram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-odnoklassniki"></i>
                <code class="mr-2">f3c5</code>
                <span>mdi-odnoklassniki</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-office"></i>
                <code class="mr-2">f3c6</code>
                <span>mdi-office</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-oil"></i>
                <code class="mr-2">f3c7</code>
                <span>mdi-oil</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-oil-temperature"></i>
                <code class="mr-2">f3c8</code>
                <span>mdi-oil-temperature</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-omega"></i>
                <code class="mr-2">f3c9</code>
                <span>mdi-omega</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-onedrive"></i>
                <code class="mr-2">f3ca</code>
                <span>mdi-onedrive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-opacity"></i>
                <code class="mr-2">f5cc</code>
                <span>mdi-opacity</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-open-in-app"></i>
                <code class="mr-2">f3cb</code>
                <span>mdi-open-in-app</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-open-in-new"></i>
                <code class="mr-2">f3cc</code>
                <span>mdi-open-in-new</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-openid"></i>
                <code class="mr-2">f3cd</code>
                <span>mdi-openid</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-opera"></i>
                <code class="mr-2">f3ce</code>
                <span>mdi-opera</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ornament"></i>
                <code class="mr-2">f3cf</code>
                <span>mdi-ornament</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ornament-variant"></i>
                <code class="mr-2">f3d0</code>
                <span>mdi-ornament-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-owl"></i>
                <code class="mr-2">f3d2</code>
                <span>mdi-owl</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-package"></i>
                <code class="mr-2">f3d3</code>
                <span>mdi-package</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-package-down"></i>
                <code class="mr-2">f3d4</code>
                <span>mdi-package-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-package-up"></i>
                <code class="mr-2">f3d5</code>
                <span>mdi-package-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-package-variant"></i>
                <code class="mr-2">f3d6</code>
                <span>mdi-package-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-package-variant-closed"></i>
                <code class="mr-2">f3d7</code>
                <span>mdi-package-variant-closed</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-first"></i>
                <code class="mr-2">f600</code>
                <span>mdi-page-first</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-last"></i>
                <code class="mr-2">f601</code>
                <span>mdi-page-last</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-layout-body"></i>
                <code class="mr-2">f6f9</code>
                <span>mdi-page-layout-body</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-layout-footer"></i>
                <code class="mr-2">f6fa</code>
                <span>mdi-page-layout-footer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-layout-header"></i>
                <code class="mr-2">f6fb</code>
                <span>mdi-page-layout-header</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-layout-sidebar-left"></i>
                <code class="mr-2">f6fc</code>
                <span>mdi-page-layout-sidebar-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-page-layout-sidebar-right"></i>
                <code class="mr-2">f6fd</code>
                <span>mdi-page-layout-sidebar-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-palette"></i>
                <code class="mr-2">f3d8</code>
                <span>mdi-palette</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-palette-advanced"></i>
                <code class="mr-2">f3d9</code>
                <span>mdi-palette-advanced</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panda"></i>
                <code class="mr-2">f3da</code>
                <span>mdi-panda</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pandora"></i>
                <code class="mr-2">f3db</code>
                <span>mdi-pandora</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panorama"></i>
                <code class="mr-2">f3dc</code>
                <span>mdi-panorama</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panorama-fisheye"></i>
                <code class="mr-2">f3dd</code>
                <span>mdi-panorama-fisheye</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panorama-horizontal"></i>
                <code class="mr-2">f3de</code>
                <span>mdi-panorama-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panorama-vertical"></i>
                <code class="mr-2">f3df</code>
                <span>mdi-panorama-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-panorama-wide-angle"></i>
                <code class="mr-2">f3e0</code>
                <span>mdi-panorama-wide-angle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-paper-cut-vertical"></i>
                <code class="mr-2">f3e1</code>
                <span>mdi-paper-cut-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-paperclip"></i>
                <code class="mr-2">f3e2</code>
                <span>mdi-paperclip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-parking"></i>
                <code class="mr-2">f3e3</code>
                <span>mdi-parking</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pause"></i>
                <code class="mr-2">f3e4</code>
                <span>mdi-pause</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pause-circle"></i>
                <code class="mr-2">f3e5</code>
                <span>mdi-pause-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pause-circle-outline"></i>
                <code class="mr-2">f3e6</code>
                <span>mdi-pause-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pause-octagon"></i>
                <code class="mr-2">f3e7</code>
                <span>mdi-pause-octagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pause-octagon-outline"></i>
                <code class="mr-2">f3e8</code>
                <span>mdi-pause-octagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-paw"></i>
                <code class="mr-2">f3e9</code>
                <span>mdi-paw</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-paw-off"></i>
                <code class="mr-2">f657</code>
                <span>mdi-paw-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pen"></i>
                <code class="mr-2">f3ea</code>
                <span>mdi-pen</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil"></i>
                <code class="mr-2">f3eb</code>
                <span>mdi-pencil</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil-box"></i>
                <code class="mr-2">f3ec</code>
                <span>mdi-pencil-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil-box-outline"></i>
                <code class="mr-2">f3ed</code>
                <span>mdi-pencil-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil-circle"></i>
                <code class="mr-2">f6fe</code>
                <span>mdi-pencil-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil-lock"></i>
                <code class="mr-2">f3ee</code>
                <span>mdi-pencil-lock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pencil-off"></i>
                <code class="mr-2">f3ef</code>
                <span>mdi-pencil-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pentagon"></i>
                <code class="mr-2">f6ff</code>
                <span>mdi-pentagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pentagon-outline"></i>
                <code class="mr-2">f700</code>
                <span>mdi-pentagon-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-percent"></i>
                <code class="mr-2">f3f0</code>
                <span>mdi-percent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pharmacy"></i>
                <code class="mr-2">f3f1</code>
                <span>mdi-pharmacy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone"></i>
                <code class="mr-2">f3f2</code>
                <span>mdi-phone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-bluetooth"></i>
                <code class="mr-2">f3f3</code>
                <span>mdi-phone-bluetooth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-classic"></i>
                <code class="mr-2">f602</code>
                <span>mdi-phone-classic</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-forward"></i>
                <code class="mr-2">f3f4</code>
                <span>mdi-phone-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-hangup"></i>
                <code class="mr-2">f3f5</code>
                <span>mdi-phone-hangup</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-in-talk"></i>
                <code class="mr-2">f3f6</code>
                <span>mdi-phone-in-talk</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-incoming"></i>
                <code class="mr-2">f3f7</code>
                <span>mdi-phone-incoming</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-locked"></i>
                <code class="mr-2">f3f8</code>
                <span>mdi-phone-locked</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-log"></i>
                <code class="mr-2">f3f9</code>
                <span>mdi-phone-log</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-minus"></i>
                <code class="mr-2">f658</code>
                <span>mdi-phone-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-missed"></i>
                <code class="mr-2">f3fa</code>
                <span>mdi-phone-missed</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-outgoing"></i>
                <code class="mr-2">f3fb</code>
                <span>mdi-phone-outgoing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-paused"></i>
                <code class="mr-2">f3fc</code>
                <span>mdi-phone-paused</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-plus"></i>
                <code class="mr-2">f659</code>
                <span>mdi-phone-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-settings"></i>
                <code class="mr-2">f3fd</code>
                <span>mdi-phone-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-phone-voip"></i>
                <code class="mr-2">f3fe</code>
                <span>mdi-phone-voip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pi"></i>
                <code class="mr-2">f3ff</code>
                <span>mdi-pi</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pi-box"></i>
                <code class="mr-2">f400</code>
                <span>mdi-pi-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-piano"></i>
                <code class="mr-2">f67c</code>
                <span>mdi-piano</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pig"></i>
                <code class="mr-2">f401</code>
                <span>mdi-pig</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pill"></i>
                <code class="mr-2">f402</code>
                <span>mdi-pill</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pillar"></i>
                <code class="mr-2">f701</code>
                <span>mdi-pillar</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pin"></i>
                <code class="mr-2">f403</code>
                <span>mdi-pin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pin-off"></i>
                <code class="mr-2">f404</code>
                <span>mdi-pin-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pine-tree"></i>
                <code class="mr-2">f405</code>
                <span>mdi-pine-tree</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pine-tree-box"></i>
                <code class="mr-2">f406</code>
                <span>mdi-pine-tree-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pinterest"></i>
                <code class="mr-2">f407</code>
                <span>mdi-pinterest</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pinterest-box"></i>
                <code class="mr-2">f408</code>
                <span>mdi-pinterest-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pistol"></i>
                <code class="mr-2">f702</code>
                <span>mdi-pistol</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pizza"></i>
                <code class="mr-2">f409</code>
                <span>mdi-pizza</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plane-shield"></i>
                <code class="mr-2">f6ba</code>
                <span>mdi-plane-shield</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play"></i>
                <code class="mr-2">f40a</code>
                <span>mdi-play</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play-box-outline"></i>
                <code class="mr-2">f40b</code>
                <span>mdi-play-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play-circle"></i>
                <code class="mr-2">f40c</code>
                <span>mdi-play-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play-circle-outline"></i>
                <code class="mr-2">f40d</code>
                <span>mdi-play-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play-pause"></i>
                <code class="mr-2">f40e</code>
                <span>mdi-play-pause</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-play-protected-content"></i>
                <code class="mr-2">f40f</code>
                <span>mdi-play-protected-content</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playlist-check"></i>
                <code class="mr-2">f5c7</code>
                <span>mdi-playlist-check</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playlist-minus"></i>
                <code class="mr-2">f410</code>
                <span>mdi-playlist-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playlist-play"></i>
                <code class="mr-2">f411</code>
                <span>mdi-playlist-play</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playlist-plus"></i>
                <code class="mr-2">f412</code>
                <span>mdi-playlist-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playlist-remove"></i>
                <code class="mr-2">f413</code>
                <span>mdi-playlist-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-playstation"></i>
                <code class="mr-2">f414</code>
                <span>mdi-playstation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plex"></i>
                <code class="mr-2">f6b9</code>
                <span>mdi-plex</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus"></i>
                <code class="mr-2">f415</code>
                <span>mdi-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-box"></i>
                <code class="mr-2">f416</code>
                <span>mdi-plus-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-box-outline"></i>
                <code class="mr-2">f703</code>
                <span>mdi-plus-box-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-circle"></i>
                <code class="mr-2">f417</code>
                <span>mdi-plus-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-circle-multiple-outline"></i>
                <code class="mr-2">f418</code>
                <span>mdi-plus-circle-multiple-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-circle-outline"></i>
                <code class="mr-2">f419</code>
                <span>mdi-plus-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-network"></i>
                <code class="mr-2">f41a</code>
                <span>mdi-plus-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-one"></i>
                <code class="mr-2">f41b</code>
                <span>mdi-plus-one</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-plus-outline"></i>
                <code class="mr-2">f704</code>
                <span>mdi-plus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pocket"></i>
                <code class="mr-2">f41c</code>
                <span>mdi-pocket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pokeball"></i>
                <code class="mr-2">f41d</code>
                <span>mdi-pokeball</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-polaroid"></i>
                <code class="mr-2">f41e</code>
                <span>mdi-polaroid</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-poll"></i>
                <code class="mr-2">f41f</code>
                <span>mdi-poll</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-poll-box"></i>
                <code class="mr-2">f420</code>
                <span>mdi-poll-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-polymer"></i>
                <code class="mr-2">f421</code>
                <span>mdi-polymer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pool"></i>
                <code class="mr-2">f606</code>
                <span>mdi-pool</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-popcorn"></i>
                <code class="mr-2">f422</code>
                <span>mdi-popcorn</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pot"></i>
                <code class="mr-2">f65a</code>
                <span>mdi-pot</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pot-mix"></i>
                <code class="mr-2">f65b</code>
                <span>mdi-pot-mix</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pound"></i>
                <code class="mr-2">f423</code>
                <span>mdi-pound</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pound-box"></i>
                <code class="mr-2">f424</code>
                <span>mdi-pound-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-power"></i>
                <code class="mr-2">f425</code>
                <span>mdi-power</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-power-plug"></i>
                <code class="mr-2">f6a4</code>
                <span>mdi-power-plug</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-power-plug-off"></i>
                <code class="mr-2">f6a5</code>
                <span>mdi-power-plug-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-power-settings"></i>
                <code class="mr-2">f426</code>
                <span>mdi-power-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-power-socket"></i>
                <code class="mr-2">f427</code>
                <span>mdi-power-socket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-prescription"></i>
                <code class="mr-2">f705</code>
                <span>mdi-prescription</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-presentation"></i>
                <code class="mr-2">f428</code>
                <span>mdi-presentation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-presentation-play"></i>
                <code class="mr-2">f429</code>
                <span>mdi-presentation-play</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-printer"></i>
                <code class="mr-2">f42a</code>
                <span>mdi-printer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-printer-3d"></i>
                <code class="mr-2">f42b</code>
                <span>mdi-printer-3d</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-printer-alert"></i>
                <code class="mr-2">f42c</code>
                <span>mdi-printer-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-printer-settings"></i>
                <code class="mr-2">f706</code>
                <span>mdi-printer-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-priority-high"></i>
                <code class="mr-2">f603</code>
                <span>mdi-priority-high</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-priority-low"></i>
                <code class="mr-2">f604</code>
                <span>mdi-priority-low</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-professional-hexagon"></i>
                <code class="mr-2">f42d</code>
                <span>mdi-professional-hexagon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-projector"></i>
                <code class="mr-2">f42e</code>
                <span>mdi-projector</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-projector-screen"></i>
                <code class="mr-2">f42f</code>
                <span>mdi-projector-screen</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-publish"></i>
                <code class="mr-2">f6a6</code>
                <span>mdi-publish</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-pulse"></i>
                <code class="mr-2">f430</code>
                <span>mdi-pulse</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-puzzle"></i>
                <code class="mr-2">f431</code>
                <span>mdi-puzzle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-qqchat"></i>
                <code class="mr-2">f605</code>
                <span>mdi-qqchat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-qrcode"></i>
                <code class="mr-2">f432</code>
                <span>mdi-qrcode</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-qrcode-scan"></i>
                <code class="mr-2">f433</code>
                <span>mdi-qrcode-scan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-quadcopter"></i>
                <code class="mr-2">f434</code>
                <span>mdi-quadcopter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-quality-high"></i>
                <code class="mr-2">f435</code>
                <span>mdi-quality-high</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-quicktime"></i>
                <code class="mr-2">f436</code>
                <span>mdi-quicktime</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radar"></i>
                <code class="mr-2">f437</code>
                <span>mdi-radar</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radiator"></i>
                <code class="mr-2">f438</code>
                <span>mdi-radiator</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radio"></i>
                <code class="mr-2">f439</code>
                <span>mdi-radio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radio-handheld"></i>
                <code class="mr-2">f43a</code>
                <span>mdi-radio-handheld</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radio-tower"></i>
                <code class="mr-2">f43b</code>
                <span>mdi-radio-tower</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radioactive"></i>
                <code class="mr-2">f43c</code>
                <span>mdi-radioactive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radiobox-blank"></i>
                <code class="mr-2">f43d</code>
                <span>mdi-radiobox-blank</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-radiobox-marked"></i>
                <code class="mr-2">f43e</code>
                <span>mdi-radiobox-marked</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-raspberrypi"></i>
                <code class="mr-2">f43f</code>
                <span>mdi-raspberrypi</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-end"></i>
                <code class="mr-2">f440</code>
                <span>mdi-ray-end</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-end-arrow"></i>
                <code class="mr-2">f441</code>
                <span>mdi-ray-end-arrow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-start"></i>
                <code class="mr-2">f442</code>
                <span>mdi-ray-start</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-start-arrow"></i>
                <code class="mr-2">f443</code>
                <span>mdi-ray-start-arrow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-start-end"></i>
                <code class="mr-2">f444</code>
                <span>mdi-ray-start-end</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ray-vertex"></i>
                <code class="mr-2">f445</code>
                <span>mdi-ray-vertex</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rdio"></i>
                <code class="mr-2">f446</code>
                <span>mdi-rdio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-react"></i>
                <code class="mr-2">f707</code>
                <span>mdi-react</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-read"></i>
                <code class="mr-2">f447</code>
                <span>mdi-read</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-readability"></i>
                <code class="mr-2">f448</code>
                <span>mdi-readability</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-receipt"></i>
                <code class="mr-2">f449</code>
                <span>mdi-receipt</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-record"></i>
                <code class="mr-2">f44a</code>
                <span>mdi-record</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-record-rec"></i>
                <code class="mr-2">f44b</code>
                <span>mdi-record-rec</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-recycle"></i>
                <code class="mr-2">f44c</code>
                <span>mdi-recycle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reddit"></i>
                <code class="mr-2">f44d</code>
                <span>mdi-reddit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-redo"></i>
                <code class="mr-2">f44e</code>
                <span>mdi-redo</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-redo-variant"></i>
                <code class="mr-2">f44f</code>
                <span>mdi-redo-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-refresh"></i>
                <code class="mr-2">f450</code>
                <span>mdi-refresh</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-regex"></i>
                <code class="mr-2">f451</code>
                <span>mdi-regex</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-relative-scale"></i>
                <code class="mr-2">f452</code>
                <span>mdi-relative-scale</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reload"></i>
                <code class="mr-2">f453</code>
                <span>mdi-reload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-remote"></i>
                <code class="mr-2">f454</code>
                <span>mdi-remote</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rename-box"></i>
                <code class="mr-2">f455</code>
                <span>mdi-rename-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reorder-horizontal"></i>
                <code class="mr-2">f687</code>
                <span>mdi-reorder-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reorder-vertical"></i>
                <code class="mr-2">f688</code>
                <span>mdi-reorder-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-repeat"></i>
                <code class="mr-2">f456</code>
                <span>mdi-repeat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-repeat-off"></i>
                <code class="mr-2">f457</code>
                <span>mdi-repeat-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-repeat-once"></i>
                <code class="mr-2">f458</code>
                <span>mdi-repeat-once</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-replay"></i>
                <code class="mr-2">f459</code>
                <span>mdi-replay</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reply"></i>
                <code class="mr-2">f45a</code>
                <span>mdi-reply</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reply-all"></i>
                <code class="mr-2">f45b</code>
                <span>mdi-reply-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-reproduction"></i>
                <code class="mr-2">f45c</code>
                <span>mdi-reproduction</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-resize-bottom-right"></i>
                <code class="mr-2">f45d</code>
                <span>mdi-resize-bottom-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-responsive"></i>
                <code class="mr-2">f45e</code>
                <span>mdi-responsive</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-restart"></i>
                <code class="mr-2">f708</code>
                <span>mdi-restart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-restore"></i>
                <code class="mr-2">f6a7</code>
                <span>mdi-restore</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rewind"></i>
                <code class="mr-2">f45f</code>
                <span>mdi-rewind</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rewind-outline"></i>
                <code class="mr-2">f709</code>
                <span>mdi-rewind-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rhombus"></i>
                <code class="mr-2">f70a</code>
                <span>mdi-rhombus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rhombus-outline"></i>
                <code class="mr-2">f70b</code>
                <span>mdi-rhombus-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ribbon"></i>
                <code class="mr-2">f460</code>
                <span>mdi-ribbon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-road"></i>
                <code class="mr-2">f461</code>
                <span>mdi-road</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-road-variant"></i>
                <code class="mr-2">f462</code>
                <span>mdi-road-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-robot"></i>
                <code class="mr-2">f6a8</code>
                <span>mdi-robot</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rocket"></i>
                <code class="mr-2">f463</code>
                <span>mdi-rocket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-roomba"></i>
                <code class="mr-2">f70c</code>
                <span>mdi-roomba</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rotate-3d"></i>
                <code class="mr-2">f464</code>
                <span>mdi-rotate-3d</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rotate-left"></i>
                <code class="mr-2">f465</code>
                <span>mdi-rotate-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rotate-left-variant"></i>
                <code class="mr-2">f466</code>
                <span>mdi-rotate-left-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rotate-right"></i>
                <code class="mr-2">f467</code>
                <span>mdi-rotate-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rotate-right-variant"></i>
                <code class="mr-2">f468</code>
                <span>mdi-rotate-right-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rounded-corner"></i>
                <code class="mr-2">f607</code>
                <span>mdi-rounded-corner</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-router-wireless"></i>
                <code class="mr-2">f469</code>
                <span>mdi-router-wireless</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-routes"></i>
                <code class="mr-2">f46a</code>
                <span>mdi-routes</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rowing"></i>
                <code class="mr-2">f608</code>
                <span>mdi-rowing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rss"></i>
                <code class="mr-2">f46b</code>
                <span>mdi-rss</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-rss-box"></i>
                <code class="mr-2">f46c</code>
                <span>mdi-rss-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ruler"></i>
                <code class="mr-2">f46d</code>
                <span>mdi-ruler</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-run"></i>
                <code class="mr-2">f70d</code>
                <span>mdi-run</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-run-fast"></i>
                <code class="mr-2">f46e</code>
                <span>mdi-run-fast</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sale"></i>
                <code class="mr-2">f46f</code>
                <span>mdi-sale</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-satellite"></i>
                <code class="mr-2">f470</code>
                <span>mdi-satellite</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-satellite-variant"></i>
                <code class="mr-2">f471</code>
                <span>mdi-satellite-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-saxophone"></i>
                <code class="mr-2">f609</code>
                <span>mdi-saxophone</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-scale"></i>
                <code class="mr-2">f472</code>
                <span>mdi-scale</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-scale-balance"></i>
                <code class="mr-2">f5d1</code>
                <span>mdi-scale-balance</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-scale-bathroom"></i>
                <code class="mr-2">f473</code>
                <span>mdi-scale-bathroom</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-scanner"></i>
                <code class="mr-2">f6aa</code>
                <span>mdi-scanner</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-school"></i>
                <code class="mr-2">f474</code>
                <span>mdi-school</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-screen-rotation"></i>
                <code class="mr-2">f475</code>
                <span>mdi-screen-rotation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-screen-rotation-lock"></i>
                <code class="mr-2">f476</code>
                <span>mdi-screen-rotation-lock</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-screwdriver"></i>
                <code class="mr-2">f477</code>
                <span>mdi-screwdriver</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-script"></i>
                <code class="mr-2">f478</code>
                <span>mdi-script</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sd"></i>
                <code class="mr-2">f479</code>
                <span>mdi-sd</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seal"></i>
                <code class="mr-2">f47a</code>
                <span>mdi-seal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-search-web"></i>
                <code class="mr-2">f70e</code>
                <span>mdi-search-web</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-flat"></i>
                <code class="mr-2">f47b</code>
                <span>mdi-seat-flat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-flat-angled"></i>
                <code class="mr-2">f47c</code>
                <span>mdi-seat-flat-angled</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-individual-suite"></i>
                <code class="mr-2">f47d</code>
                <span>mdi-seat-individual-suite</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-legroom-extra"></i>
                <code class="mr-2">f47e</code>
                <span>mdi-seat-legroom-extra</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-legroom-normal"></i>
                <code class="mr-2">f47f</code>
                <span>mdi-seat-legroom-normal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-legroom-reduced"></i>
                <code class="mr-2">f480</code>
                <span>mdi-seat-legroom-reduced</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-recline-extra"></i>
                <code class="mr-2">f481</code>
                <span>mdi-seat-recline-extra</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-seat-recline-normal"></i>
                <code class="mr-2">f482</code>
                <span>mdi-seat-recline-normal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-security"></i>
                <code class="mr-2">f483</code>
                <span>mdi-security</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-security-home"></i>
                <code class="mr-2">f689</code>
                <span>mdi-security-home</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-security-network"></i>
                <code class="mr-2">f484</code>
                <span>mdi-security-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-select"></i>
                <code class="mr-2">f485</code>
                <span>mdi-select</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-select-all"></i>
                <code class="mr-2">f486</code>
                <span>mdi-select-all</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-select-inverse"></i>
                <code class="mr-2">f487</code>
                <span>mdi-select-inverse</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-select-off"></i>
                <code class="mr-2">f488</code>
                <span>mdi-select-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-selection"></i>
                <code class="mr-2">f489</code>
                <span>mdi-selection</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-send"></i>
                <code class="mr-2">f48a</code>
                <span>mdi-send</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-serial-port"></i>
                <code class="mr-2">f65c</code>
                <span>mdi-serial-port</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server"></i>
                <code class="mr-2">f48b</code>
                <span>mdi-server</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-minus"></i>
                <code class="mr-2">f48c</code>
                <span>mdi-server-minus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-network"></i>
                <code class="mr-2">f48d</code>
                <span>mdi-server-network</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-network-off"></i>
                <code class="mr-2">f48e</code>
                <span>mdi-server-network-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-off"></i>
                <code class="mr-2">f48f</code>
                <span>mdi-server-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-plus"></i>
                <code class="mr-2">f490</code>
                <span>mdi-server-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-remove"></i>
                <code class="mr-2">f491</code>
                <span>mdi-server-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-server-security"></i>
                <code class="mr-2">f492</code>
                <span>mdi-server-security</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-settings"></i>
                <code class="mr-2">f493</code>
                <span>mdi-settings</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-settings-box"></i>
                <code class="mr-2">f494</code>
                <span>mdi-settings-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shape-circle-plus"></i>
                <code class="mr-2">f65d</code>
                <span>mdi-shape-circle-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shape-plus"></i>
                <code class="mr-2">f495</code>
                <span>mdi-shape-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shape-polygon-plus"></i>
                <code class="mr-2">f65e</code>
                <span>mdi-shape-polygon-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shape-rectangle-plus"></i>
                <code class="mr-2">f65f</code>
                <span>mdi-shape-rectangle-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shape-square-plus"></i>
                <code class="mr-2">f660</code>
                <span>mdi-shape-square-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-share"></i>
                <code class="mr-2">f496</code>
                <span>mdi-share</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-share-variant"></i>
                <code class="mr-2">f497</code>
                <span>mdi-share-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shield"></i>
                <code class="mr-2">f498</code>
                <span>mdi-shield</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shield-outline"></i>
                <code class="mr-2">f499</code>
                <span>mdi-shield-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shopping"></i>
                <code class="mr-2">f49a</code>
                <span>mdi-shopping</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shopping-music"></i>
                <code class="mr-2">f49b</code>
                <span>mdi-shopping-music</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shovel"></i>
                <code class="mr-2">f70f</code>
                <span>mdi-shovel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shovel-off"></i>
                <code class="mr-2">f710</code>
                <span>mdi-shovel-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shredder"></i>
                <code class="mr-2">f49c</code>
                <span>mdi-shredder</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shuffle"></i>
                <code class="mr-2">f49d</code>
                <span>mdi-shuffle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shuffle-disabled"></i>
                <code class="mr-2">f49e</code>
                <span>mdi-shuffle-disabled</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-shuffle-variant"></i>
                <code class="mr-2">f49f</code>
                <span>mdi-shuffle-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sigma"></i>
                <code class="mr-2">f4a0</code>
                <span>mdi-sigma</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sigma-lower"></i>
                <code class="mr-2">f62b</code>
                <span>mdi-sigma-lower</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sign-caution"></i>
                <code class="mr-2">f4a1</code>
                <span>mdi-sign-caution</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal"></i>
                <code class="mr-2">f4a2</code>
                <span>mdi-signal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-2g"></i>
                <code class="mr-2">f711</code>
                <span>mdi-signal-2g</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-3g"></i>
                <code class="mr-2">f712</code>
                <span>mdi-signal-3g</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-4g"></i>
                <code class="mr-2">f713</code>
                <span>mdi-signal-4g</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-hspa"></i>
                <code class="mr-2">f714</code>
                <span>mdi-signal-hspa</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-hspa-plus"></i>
                <code class="mr-2">f715</code>
                <span>mdi-signal-hspa-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-signal-variant"></i>
                <code class="mr-2">f60a</code>
                <span>mdi-signal-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-silverware"></i>
                <code class="mr-2">f4a3</code>
                <span>mdi-silverware</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-silverware-fork"></i>
                <code class="mr-2">f4a4</code>
                <span>mdi-silverware-fork</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-silverware-spoon"></i>
                <code class="mr-2">f4a5</code>
                <span>mdi-silverware-spoon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-silverware-variant"></i>
                <code class="mr-2">f4a6</code>
                <span>mdi-silverware-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sim"></i>
                <code class="mr-2">f4a7</code>
                <span>mdi-sim</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sim-alert"></i>
                <code class="mr-2">f4a8</code>
                <span>mdi-sim-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sim-off"></i>
                <code class="mr-2">f4a9</code>
                <span>mdi-sim-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sitemap"></i>
                <code class="mr-2">f4aa</code>
                <span>mdi-sitemap</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-backward"></i>
                <code class="mr-2">f4ab</code>
                <span>mdi-skip-backward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-forward"></i>
                <code class="mr-2">f4ac</code>
                <span>mdi-skip-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-next"></i>
                <code class="mr-2">f4ad</code>
                <span>mdi-skip-next</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-next-circle"></i>
                <code class="mr-2">f661</code>
                <span>mdi-skip-next-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-next-circle-outline"></i>
                <code class="mr-2">f662</code>
                <span>mdi-skip-next-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-previous"></i>
                <code class="mr-2">f4ae</code>
                <span>mdi-skip-previous</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-previous-circle"></i>
                <code class="mr-2">f663</code>
                <span>mdi-skip-previous-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skip-previous-circle-outline"></i>
                <code class="mr-2">f664</code>
                <span>mdi-skip-previous-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skull"></i>
                <code class="mr-2">f68b</code>
                <span>mdi-skull</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skype"></i>
                <code class="mr-2">f4af</code>
                <span>mdi-skype</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-skype-business"></i>
                <code class="mr-2">f4b0</code>
                <span>mdi-skype-business</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-slack"></i>
                <code class="mr-2">f4b1</code>
                <span>mdi-slack</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sleep"></i>
                <code class="mr-2">f4b2</code>
                <span>mdi-sleep</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sleep-off"></i>
                <code class="mr-2">f4b3</code>
                <span>mdi-sleep-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-smoking"></i>
                <code class="mr-2">f4b4</code>
                <span>mdi-smoking</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-smoking-off"></i>
                <code class="mr-2">f4b5</code>
                <span>mdi-smoking-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-snapchat"></i>
                <code class="mr-2">f4b6</code>
                <span>mdi-snapchat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-snowflake"></i>
                <code class="mr-2">f716</code>
                <span>mdi-snowflake</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-snowman"></i>
                <code class="mr-2">f4b7</code>
                <span>mdi-snowman</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-soccer"></i>
                <code class="mr-2">f4b8</code>
                <span>mdi-soccer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sofa"></i>
                <code class="mr-2">f4b9</code>
                <span>mdi-sofa</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-solid"></i>
                <code class="mr-2">f68c</code>
                <span>mdi-solid</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort"></i>
                <code class="mr-2">f4ba</code>
                <span>mdi-sort</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort-alphabetical"></i>
                <code class="mr-2">f4bb</code>
                <span>mdi-sort-alphabetical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort-ascending"></i>
                <code class="mr-2">f4bc</code>
                <span>mdi-sort-ascending</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort-descending"></i>
                <code class="mr-2">f4bd</code>
                <span>mdi-sort-descending</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort-numeric"></i>
                <code class="mr-2">f4be</code>
                <span>mdi-sort-numeric</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sort-variant"></i>
                <code class="mr-2">f4bf</code>
                <span>mdi-sort-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-soundcloud"></i>
                <code class="mr-2">f4c0</code>
                <span>mdi-soundcloud</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-branch"></i>
                <code class="mr-2">f62c</code>
                <span>mdi-source-branch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit"></i>
                <code class="mr-2">f717</code>
                <span>mdi-source-commit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-end"></i>
                <code class="mr-2">f718</code>
                <span>mdi-source-commit-end</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-end-local"></i>
                <code class="mr-2">f719</code>
                <span>mdi-source-commit-end-local</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-local"></i>
                <code class="mr-2">f71a</code>
                <span>mdi-source-commit-local</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-next-local"></i>
                <code class="mr-2">f71b</code>
                <span>mdi-source-commit-next-local</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-start"></i>
                <code class="mr-2">f71c</code>
                <span>mdi-source-commit-start</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-commit-start-next-local"></i>
                <code class="mr-2">f71d</code>
                <span>mdi-source-commit-start-next-local</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-fork"></i>
                <code class="mr-2">f4c1</code>
                <span>mdi-source-fork</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-merge"></i>
                <code class="mr-2">f62d</code>
                <span>mdi-source-merge</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-source-pull"></i>
                <code class="mr-2">f4c2</code>
                <span>mdi-source-pull</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-speaker"></i>
                <code class="mr-2">f4c3</code>
                <span>mdi-speaker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-speaker-off"></i>
                <code class="mr-2">f4c4</code>
                <span>mdi-speaker-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-speaker-wireless"></i>
                <code class="mr-2">f71e</code>
                <span>mdi-speaker-wireless</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-speedometer"></i>
                <code class="mr-2">f4c5</code>
                <span>mdi-speedometer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-spellcheck"></i>
                <code class="mr-2">f4c6</code>
                <span>mdi-spellcheck</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-spotify"></i>
                <code class="mr-2">f4c7</code>
                <span>mdi-spotify</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-spotlight"></i>
                <code class="mr-2">f4c8</code>
                <span>mdi-spotlight</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-spotlight-beam"></i>
                <code class="mr-2">f4c9</code>
                <span>mdi-spotlight-beam</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-spray"></i>
                <code class="mr-2">f665</code>
                <span>mdi-spray</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-square-inc"></i>
                <code class="mr-2">f4ca</code>
                <span>mdi-square-inc</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-square-inc-cash"></i>
                <code class="mr-2">f4cb</code>
                <span>mdi-square-inc-cash</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stackexchange"></i>
                <code class="mr-2">f60b</code>
                <span>mdi-stackexchange</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stackoverflow"></i>
                <code class="mr-2">f4cc</code>
                <span>mdi-stackoverflow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stadium"></i>
                <code class="mr-2">f71f</code>
                <span>mdi-stadium</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stairs"></i>
                <code class="mr-2">f4cd</code>
                <span>mdi-stairs</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-star"></i>
                <code class="mr-2">f4ce</code>
                <span>mdi-star</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-star-circle"></i>
                <code class="mr-2">f4cf</code>
                <span>mdi-star-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-star-half"></i>
                <code class="mr-2">f4d0</code>
                <span>mdi-star-half</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-star-off"></i>
                <code class="mr-2">f4d1</code>
                <span>mdi-star-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-star-outline"></i>
                <code class="mr-2">f4d2</code>
                <span>mdi-star-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-steam"></i>
                <code class="mr-2">f4d3</code>
                <span>mdi-steam</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-steering"></i>
                <code class="mr-2">f4d4</code>
                <span>mdi-steering</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-step-backward"></i>
                <code class="mr-2">f4d5</code>
                <span>mdi-step-backward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-step-backward-2"></i>
                <code class="mr-2">f4d6</code>
                <span>mdi-step-backward-2</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-step-forward"></i>
                <code class="mr-2">f4d7</code>
                <span>mdi-step-forward</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-step-forward-2"></i>
                <code class="mr-2">f4d8</code>
                <span>mdi-step-forward-2</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stethoscope"></i>
                <code class="mr-2">f4d9</code>
                <span>mdi-stethoscope</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sticker"></i>
                <code class="mr-2">f5d0</code>
                <span>mdi-sticker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stocking"></i>
                <code class="mr-2">f4da</code>
                <span>mdi-stocking</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stop"></i>
                <code class="mr-2">f4db</code>
                <span>mdi-stop</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stop-circle"></i>
                <code class="mr-2">f666</code>
                <span>mdi-stop-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stop-circle-outline"></i>
                <code class="mr-2">f667</code>
                <span>mdi-stop-circle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-store"></i>
                <code class="mr-2">f4dc</code>
                <span>mdi-store</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-store-24-hour"></i>
                <code class="mr-2">f4dd</code>
                <span>mdi-store-24-hour</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-stove"></i>
                <code class="mr-2">f4de</code>
                <span>mdi-stove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-subdirectory-arrow-left"></i>
                <code class="mr-2">f60c</code>
                <span>mdi-subdirectory-arrow-left</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-subdirectory-arrow-right"></i>
                <code class="mr-2">f60d</code>
                <span>mdi-subdirectory-arrow-right</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-subway"></i>
                <code class="mr-2">f6ab</code>
                <span>mdi-subway</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-subway-variant"></i>
                <code class="mr-2">f4df</code>
                <span>mdi-subway-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sunglasses"></i>
                <code class="mr-2">f4e0</code>
                <span>mdi-sunglasses</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-surround-sound"></i>
                <code class="mr-2">f5c5</code>
                <span>mdi-surround-sound</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-svg"></i>
                <code class="mr-2">f720</code>
                <span>mdi-svg</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-swap-horizontal"></i>
                <code class="mr-2">f4e1</code>
                <span>mdi-swap-horizontal</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-swap-vertical"></i>
                <code class="mr-2">f4e2</code>
                <span>mdi-swap-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-swim"></i>
                <code class="mr-2">f4e3</code>
                <span>mdi-swim</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-switch"></i>
                <code class="mr-2">f4e4</code>
                <span>mdi-switch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sword"></i>
                <code class="mr-2">f4e5</code>
                <span>mdi-sword</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sync"></i>
                <code class="mr-2">f4e6</code>
                <span>mdi-sync</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sync-alert"></i>
                <code class="mr-2">f4e7</code>
                <span>mdi-sync-alert</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-sync-off"></i>
                <code class="mr-2">f4e8</code>
                <span>mdi-sync-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tab"></i>
                <code class="mr-2">f4e9</code>
                <span>mdi-tab</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tab-unselected"></i>
                <code class="mr-2">f4ea</code>
                <span>mdi-tab-unselected</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table"></i>
                <code class="mr-2">f4eb</code>
                <span>mdi-table</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-column-plus-after"></i>
                <code class="mr-2">f4ec</code>
                <span>mdi-table-column-plus-after</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-column-plus-before"></i>
                <code class="mr-2">f4ed</code>
                <span>mdi-table-column-plus-before</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-column-remove"></i>
                <code class="mr-2">f4ee</code>
                <span>mdi-table-column-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-column-width"></i>
                <code class="mr-2">f4ef</code>
                <span>mdi-table-column-width</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-edit"></i>
                <code class="mr-2">f4f0</code>
                <span>mdi-table-edit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-large"></i>
                <code class="mr-2">f4f1</code>
                <span>mdi-table-large</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-row-height"></i>
                <code class="mr-2">f4f2</code>
                <span>mdi-table-row-height</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-row-plus-after"></i>
                <code class="mr-2">f4f3</code>
                <span>mdi-table-row-plus-after</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-row-plus-before"></i>
                <code class="mr-2">f4f4</code>
                <span>mdi-table-row-plus-before</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-table-row-remove"></i>
                <code class="mr-2">f4f5</code>
                <span>mdi-table-row-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tablet"></i>
                <code class="mr-2">f4f6</code>
                <span>mdi-tablet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tablet-android"></i>
                <code class="mr-2">f4f7</code>
                <span>mdi-tablet-android</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tablet-ipad"></i>
                <code class="mr-2">f4f8</code>
                <span>mdi-tablet-ipad</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag"></i>
                <code class="mr-2">f4f9</code>
                <span>mdi-tag</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-faces"></i>
                <code class="mr-2">f4fa</code>
                <span>mdi-tag-faces</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-heart"></i>
                <code class="mr-2">f68a</code>
                <span>mdi-tag-heart</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-multiple"></i>
                <code class="mr-2">f4fb</code>
                <span>mdi-tag-multiple</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-outline"></i>
                <code class="mr-2">f4fc</code>
                <span>mdi-tag-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-plus"></i>
                <code class="mr-2">f721</code>
                <span>mdi-tag-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-remove"></i>
                <code class="mr-2">f722</code>
                <span>mdi-tag-remove</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tag-text-outline"></i>
                <code class="mr-2">f4fd</code>
                <span>mdi-tag-text-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-target"></i>
                <code class="mr-2">f4fe</code>
                <span>mdi-target</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-taxi"></i>
                <code class="mr-2">f4ff</code>
                <span>mdi-taxi</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-teamviewer"></i>
                <code class="mr-2">f500</code>
                <span>mdi-teamviewer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-telegram"></i>
                <code class="mr-2">f501</code>
                <span>mdi-telegram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-television"></i>
                <code class="mr-2">f502</code>
                <span>mdi-television</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-television-guide"></i>
                <code class="mr-2">f503</code>
                <span>mdi-television-guide</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-temperature-celsius"></i>
                <code class="mr-2">f504</code>
                <span>mdi-temperature-celsius</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-temperature-fahrenheit"></i>
                <code class="mr-2">f505</code>
                <span>mdi-temperature-fahrenheit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-temperature-kelvin"></i>
                <code class="mr-2">f506</code>
                <span>mdi-temperature-kelvin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tennis"></i>
                <code class="mr-2">f507</code>
                <span>mdi-tennis</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tent"></i>
                <code class="mr-2">f508</code>
                <span>mdi-tent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-terrain"></i>
                <code class="mr-2">f509</code>
                <span>mdi-terrain</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-test-tube"></i>
                <code class="mr-2">f668</code>
                <span>mdi-test-tube</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-text-shadow"></i>
                <code class="mr-2">f669</code>
                <span>mdi-text-shadow</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-text-to-speech"></i>
                <code class="mr-2">f50a</code>
                <span>mdi-text-to-speech</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-text-to-speech-off"></i>
                <code class="mr-2">f50b</code>
                <span>mdi-text-to-speech-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-textbox"></i>
                <code class="mr-2">f60e</code>
                <span>mdi-textbox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-texture"></i>
                <code class="mr-2">f50c</code>
                <span>mdi-texture</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-theater"></i>
                <code class="mr-2">f50d</code>
                <span>mdi-theater</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-theme-light-dark"></i>
                <code class="mr-2">f50e</code>
                <span>mdi-theme-light-dark</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thermometer"></i>
                <code class="mr-2">f50f</code>
                <span>mdi-thermometer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thermometer-lines"></i>
                <code class="mr-2">f510</code>
                <span>mdi-thermometer-lines</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thumb-down"></i>
                <code class="mr-2">f511</code>
                <span>mdi-thumb-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thumb-down-outline"></i>
                <code class="mr-2">f512</code>
                <span>mdi-thumb-down-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thumb-up"></i>
                <code class="mr-2">f513</code>
                <span>mdi-thumb-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thumb-up-outline"></i>
                <code class="mr-2">f514</code>
                <span>mdi-thumb-up-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-thumbs-up-down"></i>
                <code class="mr-2">f515</code>
                <span>mdi-thumbs-up-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ticket"></i>
                <code class="mr-2">f516</code>
                <span>mdi-ticket</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ticket-account"></i>
                <code class="mr-2">f517</code>
                <span>mdi-ticket-account</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ticket-confirmation"></i>
                <code class="mr-2">f518</code>
                <span>mdi-ticket-confirmation</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ticket-percent"></i>
                <code class="mr-2">f723</code>
                <span>mdi-ticket-percent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tie"></i>
                <code class="mr-2">f519</code>
                <span>mdi-tie</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tilde"></i>
                <code class="mr-2">f724</code>
                <span>mdi-tilde</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timelapse"></i>
                <code class="mr-2">f51a</code>
                <span>mdi-timelapse</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer"></i>
                <code class="mr-2">f51b</code>
                <span>mdi-timer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer-10"></i>
                <code class="mr-2">f51c</code>
                <span>mdi-timer-10</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer-3"></i>
                <code class="mr-2">f51d</code>
                <span>mdi-timer-3</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer-off"></i>
                <code class="mr-2">f51e</code>
                <span>mdi-timer-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer-sand"></i>
                <code class="mr-2">f51f</code>
                <span>mdi-timer-sand</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timer-sand-empty"></i>
                <code class="mr-2">f6ac</code>
                <span>mdi-timer-sand-empty</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-timetable"></i>
                <code class="mr-2">f520</code>
                <span>mdi-timetable</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-toggle-switch"></i>
                <code class="mr-2">f521</code>
                <span>mdi-toggle-switch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-toggle-switch-off"></i>
                <code class="mr-2">f522</code>
                <span>mdi-toggle-switch-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip"></i>
                <code class="mr-2">f523</code>
                <span>mdi-tooltip</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip-edit"></i>
                <code class="mr-2">f524</code>
                <span>mdi-tooltip-edit</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip-image"></i>
                <code class="mr-2">f525</code>
                <span>mdi-tooltip-image</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip-outline"></i>
                <code class="mr-2">f526</code>
                <span>mdi-tooltip-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip-outline-plus"></i>
                <code class="mr-2">f527</code>
                <span>mdi-tooltip-outline-plus</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooltip-text"></i>
                <code class="mr-2">f528</code>
                <span>mdi-tooltip-text</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tooth"></i>
                <code class="mr-2">f529</code>
                <span>mdi-tooth</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tor"></i>
                <code class="mr-2">f52a</code>
                <span>mdi-tor</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tower-beach"></i>
                <code class="mr-2">f680</code>
                <span>mdi-tower-beach</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tower-fire"></i>
                <code class="mr-2">f681</code>
                <span>mdi-tower-fire</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-traffic-light"></i>
                <code class="mr-2">f52b</code>
                <span>mdi-traffic-light</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-train"></i>
                <code class="mr-2">f52c</code>
                <span>mdi-train</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tram"></i>
                <code class="mr-2">f52d</code>
                <span>mdi-tram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-transcribe"></i>
                <code class="mr-2">f52e</code>
                <span>mdi-transcribe</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-transcribe-close"></i>
                <code class="mr-2">f52f</code>
                <span>mdi-transcribe-close</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-transfer"></i>
                <code class="mr-2">f530</code>
                <span>mdi-transfer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-transit-transfer"></i>
                <code class="mr-2">f6ad</code>
                <span>mdi-transit-transfer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-translate"></i>
                <code class="mr-2">f5ca</code>
                <span>mdi-translate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-treasure-chest"></i>
                <code class="mr-2">f725</code>
                <span>mdi-treasure-chest</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tree"></i>
                <code class="mr-2">f531</code>
                <span>mdi-tree</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trello"></i>
                <code class="mr-2">f532</code>
                <span>mdi-trello</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trending-down"></i>
                <code class="mr-2">f533</code>
                <span>mdi-trending-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trending-neutral"></i>
                <code class="mr-2">f534</code>
                <span>mdi-trending-neutral</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trending-up"></i>
                <code class="mr-2">f535</code>
                <span>mdi-trending-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-triangle"></i>
                <code class="mr-2">f536</code>
                <span>mdi-triangle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-triangle-outline"></i>
                <code class="mr-2">f537</code>
                <span>mdi-triangle-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trophy"></i>
                <code class="mr-2">f538</code>
                <span>mdi-trophy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trophy-award"></i>
                <code class="mr-2">f539</code>
                <span>mdi-trophy-award</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trophy-outline"></i>
                <code class="mr-2">f53a</code>
                <span>mdi-trophy-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trophy-variant"></i>
                <code class="mr-2">f53b</code>
                <span>mdi-trophy-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-trophy-variant-outline"></i>
                <code class="mr-2">f53c</code>
                <span>mdi-trophy-variant-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-truck"></i>
                <code class="mr-2">f53d</code>
                <span>mdi-truck</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-truck-delivery"></i>
                <code class="mr-2">f53e</code>
                <span>mdi-truck-delivery</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-truck-trailer"></i>
                <code class="mr-2">f726</code>
                <span>mdi-truck-trailer</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tshirt-crew"></i>
                <code class="mr-2">f53f</code>
                <span>mdi-tshirt-crew</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tshirt-v"></i>
                <code class="mr-2">f540</code>
                <span>mdi-tshirt-v</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tumblr"></i>
                <code class="mr-2">f541</code>
                <span>mdi-tumblr</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tumblr-reblog"></i>
                <code class="mr-2">f542</code>
                <span>mdi-tumblr-reblog</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tune"></i>
                <code class="mr-2">f62e</code>
                <span>mdi-tune</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-tune-vertical"></i>
                <code class="mr-2">f66a</code>
                <span>mdi-tune-vertical</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-twitch"></i>
                <code class="mr-2">f543</code>
                <span>mdi-twitch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-twitter"></i>
                <code class="mr-2">f544</code>
                <span>mdi-twitter</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-twitter-box"></i>
                <code class="mr-2">f545</code>
                <span>mdi-twitter-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-twitter-circle"></i>
                <code class="mr-2">f546</code>
                <span>mdi-twitter-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-twitter-retweet"></i>
                <code class="mr-2">f547</code>
                <span>mdi-twitter-retweet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ubuntu"></i>
                <code class="mr-2">f548</code>
                <span>mdi-ubuntu</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-umbraco"></i>
                <code class="mr-2">f549</code>
                <span>mdi-umbraco</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-umbrella"></i>
                <code class="mr-2">f54a</code>
                <span>mdi-umbrella</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-umbrella-outline"></i>
                <code class="mr-2">f54b</code>
                <span>mdi-umbrella-outline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-undo"></i>
                <code class="mr-2">f54c</code>
                <span>mdi-undo</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-undo-variant"></i>
                <code class="mr-2">f54d</code>
                <span>mdi-undo-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-unfold-less"></i>
                <code class="mr-2">f54e</code>
                <span>mdi-unfold-less</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-unfold-more"></i>
                <code class="mr-2">f54f</code>
                <span>mdi-unfold-more</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-ungroup"></i>
                <code class="mr-2">f550</code>
                <span>mdi-ungroup</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-unity"></i>
                <code class="mr-2">f6ae</code>
                <span>mdi-unity</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-untappd"></i>
                <code class="mr-2">f551</code>
                <span>mdi-untappd</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-update"></i>
                <code class="mr-2">f6af</code>
                <span>mdi-update</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-upload"></i>
                <code class="mr-2">f552</code>
                <span>mdi-upload</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-usb"></i>
                <code class="mr-2">f553</code>
                <span>mdi-usb</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-arrange-above"></i>
                <code class="mr-2">f554</code>
                <span>mdi-vector-arrange-above</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-arrange-below"></i>
                <code class="mr-2">f555</code>
                <span>mdi-vector-arrange-below</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-circle"></i>
                <code class="mr-2">f556</code>
                <span>mdi-vector-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-circle-variant"></i>
                <code class="mr-2">f557</code>
                <span>mdi-vector-circle-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-combine"></i>
                <code class="mr-2">f558</code>
                <span>mdi-vector-combine</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-curve"></i>
                <code class="mr-2">f559</code>
                <span>mdi-vector-curve</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-difference"></i>
                <code class="mr-2">f55a</code>
                <span>mdi-vector-difference</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-difference-ab"></i>
                <code class="mr-2">f55b</code>
                <span>mdi-vector-difference-ab</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-difference-ba"></i>
                <code class="mr-2">f55c</code>
                <span>mdi-vector-difference-ba</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-intersection"></i>
                <code class="mr-2">f55d</code>
                <span>mdi-vector-intersection</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-line"></i>
                <code class="mr-2">f55e</code>
                <span>mdi-vector-line</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-point"></i>
                <code class="mr-2">f55f</code>
                <span>mdi-vector-point</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-polygon"></i>
                <code class="mr-2">f560</code>
                <span>mdi-vector-polygon</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-polyline"></i>
                <code class="mr-2">f561</code>
                <span>mdi-vector-polyline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-rectangle"></i>
                <code class="mr-2">f5c6</code>
                <span>mdi-vector-rectangle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-selection"></i>
                <code class="mr-2">f562</code>
                <span>mdi-vector-selection</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-square"></i>
                <code class="mr-2">f001</code>
                <span>mdi-vector-square</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-triangle"></i>
                <code class="mr-2">f563</code>
                <span>mdi-vector-triangle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vector-union"></i>
                <code class="mr-2">f564</code>
                <span>mdi-vector-union</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-verified"></i>
                <code class="mr-2">f565</code>
                <span>mdi-verified</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vibrate"></i>
                <code class="mr-2">f566</code>
                <span>mdi-vibrate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-video"></i>
                <code class="mr-2">f567</code>
                <span>mdi-video</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-video-off"></i>
                <code class="mr-2">f568</code>
                <span>mdi-video-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-video-switch"></i>
                <code class="mr-2">f569</code>
                <span>mdi-video-switch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-agenda"></i>
                <code class="mr-2">f56a</code>
                <span>mdi-view-agenda</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-array"></i>
                <code class="mr-2">f56b</code>
                <span>mdi-view-array</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-carousel"></i>
                <code class="mr-2">f56c</code>
                <span>mdi-view-carousel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-column"></i>
                <code class="mr-2">f56d</code>
                <span>mdi-view-column</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-dashboard"></i>
                <code class="mr-2">f56e</code>
                <span>mdi-view-dashboard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-day"></i>
                <code class="mr-2">f56f</code>
                <span>mdi-view-day</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-grid"></i>
                <code class="mr-2">f570</code>
                <span>mdi-view-grid</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-headline"></i>
                <code class="mr-2">f571</code>
                <span>mdi-view-headline</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-list"></i>
                <code class="mr-2">f572</code>
                <span>mdi-view-list</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-module"></i>
                <code class="mr-2">f573</code>
                <span>mdi-view-module</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-parallel"></i>
                <code class="mr-2">f727</code>
                <span>mdi-view-parallel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-quilt"></i>
                <code class="mr-2">f574</code>
                <span>mdi-view-quilt</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-sequential"></i>
                <code class="mr-2">f728</code>
                <span>mdi-view-sequential</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-stream"></i>
                <code class="mr-2">f575</code>
                <span>mdi-view-stream</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-view-week"></i>
                <code class="mr-2">f576</code>
                <span>mdi-view-week</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vimeo"></i>
                <code class="mr-2">f577</code>
                <span>mdi-vimeo</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vine"></i>
                <code class="mr-2">f578</code>
                <span>mdi-vine</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-violin"></i>
                <code class="mr-2">f60f</code>
                <span>mdi-violin</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-visualstudio"></i>
                <code class="mr-2">f610</code>
                <span>mdi-visualstudio</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vk"></i>
                <code class="mr-2">f579</code>
                <span>mdi-vk</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vk-box"></i>
                <code class="mr-2">f57a</code>
                <span>mdi-vk-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vk-circle"></i>
                <code class="mr-2">f57b</code>
                <span>mdi-vk-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vlc"></i>
                <code class="mr-2">f57c</code>
                <span>mdi-vlc</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-voice"></i>
                <code class="mr-2">f5cb</code>
                <span>mdi-voice</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-voicemail"></i>
                <code class="mr-2">f57d</code>
                <span>mdi-voicemail</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-volume-high"></i>
                <code class="mr-2">f57e</code>
                <span>mdi-volume-high</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-volume-low"></i>
                <code class="mr-2">f57f</code>
                <span>mdi-volume-low</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-volume-medium"></i>
                <code class="mr-2">f580</code>
                <span>mdi-volume-medium</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-volume-off"></i>
                <code class="mr-2">f581</code>
                <span>mdi-volume-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-vpn"></i>
                <code class="mr-2">f582</code>
                <span>mdi-vpn</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-walk"></i>
                <code class="mr-2">f583</code>
                <span>mdi-walk</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wallet"></i>
                <code class="mr-2">f584</code>
                <span>mdi-wallet</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wallet-giftcard"></i>
                <code class="mr-2">f585</code>
                <span>mdi-wallet-giftcard</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wallet-membership"></i>
                <code class="mr-2">f586</code>
                <span>mdi-wallet-membership</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wallet-travel"></i>
                <code class="mr-2">f587</code>
                <span>mdi-wallet-travel</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wan"></i>
                <code class="mr-2">f588</code>
                <span>mdi-wan</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-washing-machine"></i>
                <code class="mr-2">f729</code>
                <span>mdi-washing-machine</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-watch"></i>
                <code class="mr-2">f589</code>
                <span>mdi-watch</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-watch-export"></i>
                <code class="mr-2">f58a</code>
                <span>mdi-watch-export</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-watch-import"></i>
                <code class="mr-2">f58b</code>
                <span>mdi-watch-import</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-watch-vibrate"></i>
                <code class="mr-2">f6b0</code>
                <span>mdi-watch-vibrate</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-water"></i>
                <code class="mr-2">f58c</code>
                <span>mdi-water</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-water-off"></i>
                <code class="mr-2">f58d</code>
                <span>mdi-water-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-water-percent"></i>
                <code class="mr-2">f58e</code>
                <span>mdi-water-percent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-water-pump"></i>
                <code class="mr-2">f58f</code>
                <span>mdi-water-pump</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-watermark"></i>
                <code class="mr-2">f612</code>
                <span>mdi-watermark</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-cloudy"></i>
                <code class="mr-2">f590</code>
                <span>mdi-weather-cloudy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-fog"></i>
                <code class="mr-2">f591</code>
                <span>mdi-weather-fog</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-hail"></i>
                <code class="mr-2">f592</code>
                <span>mdi-weather-hail</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-lightning"></i>
                <code class="mr-2">f593</code>
                <span>mdi-weather-lightning</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-lightning-rainy"></i>
                <code class="mr-2">f67d</code>
                <span>mdi-weather-lightning-rainy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-night"></i>
                <code class="mr-2">f594</code>
                <span>mdi-weather-night</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-partlycloudy"></i>
                <code class="mr-2">f595</code>
                <span>mdi-weather-partlycloudy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-pouring"></i>
                <code class="mr-2">f596</code>
                <span>mdi-weather-pouring</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-rainy"></i>
                <code class="mr-2">f597</code>
                <span>mdi-weather-rainy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-snowy"></i>
                <code class="mr-2">f598</code>
                <span>mdi-weather-snowy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-snowy-rainy"></i>
                <code class="mr-2">f67e</code>
                <span>mdi-weather-snowy-rainy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-sunny"></i>
                <code class="mr-2">f599</code>
                <span>mdi-weather-sunny</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-sunset"></i>
                <code class="mr-2">f59a</code>
                <span>mdi-weather-sunset</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-sunset-down"></i>
                <code class="mr-2">f59b</code>
                <span>mdi-weather-sunset-down</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-sunset-up"></i>
                <code class="mr-2">f59c</code>
                <span>mdi-weather-sunset-up</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-windy"></i>
                <code class="mr-2">f59d</code>
                <span>mdi-weather-windy</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weather-windy-variant"></i>
                <code class="mr-2">f59e</code>
                <span>mdi-weather-windy-variant</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-web"></i>
                <code class="mr-2">f59f</code>
                <span>mdi-web</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-webcam"></i>
                <code class="mr-2">f5a0</code>
                <span>mdi-webcam</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-webhook"></i>
                <code class="mr-2">f62f</code>
                <span>mdi-webhook</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-webpack"></i>
                <code class="mr-2">f72a</code>
                <span>mdi-webpack</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wechat"></i>
                <code class="mr-2">f611</code>
                <span>mdi-wechat</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weight"></i>
                <code class="mr-2">f5a1</code>
                <span>mdi-weight</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-weight-kilogram"></i>
                <code class="mr-2">f5a2</code>
                <span>mdi-weight-kilogram</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-whatsapp"></i>
                <code class="mr-2">f5a3</code>
                <span>mdi-whatsapp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wheelchair-accessibility"></i>
                <code class="mr-2">f5a4</code>
                <span>mdi-wheelchair-accessibility</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-white-balance-auto"></i>
                <code class="mr-2">f5a5</code>
                <span>mdi-white-balance-auto</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-white-balance-incandescent"></i>
                <code class="mr-2">f5a6</code>
                <span>mdi-white-balance-incandescent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-white-balance-iridescent"></i>
                <code class="mr-2">f5a7</code>
                <span>mdi-white-balance-iridescent</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-white-balance-sunny"></i>
                <code class="mr-2">f5a8</code>
                <span>mdi-white-balance-sunny</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-widgets"></i>
                <code class="mr-2">f72b</code>
                <span>mdi-widgets</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wifi"></i>
                <code class="mr-2">f5a9</code>
                <span>mdi-wifi</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wifi-off"></i>
                <code class="mr-2">f5aa</code>
                <span>mdi-wifi-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wii"></i>
                <code class="mr-2">f5ab</code>
                <span>mdi-wii</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wiiu"></i>
                <code class="mr-2">f72c</code>
                <span>mdi-wiiu</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wikipedia"></i>
                <code class="mr-2">f5ac</code>
                <span>mdi-wikipedia</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-close"></i>
                <code class="mr-2">f5ad</code>
                <span>mdi-window-close</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-closed"></i>
                <code class="mr-2">f5ae</code>
                <span>mdi-window-closed</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-maximize"></i>
                <code class="mr-2">f5af</code>
                <span>mdi-window-maximize</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-minimize"></i>
                <code class="mr-2">f5b0</code>
                <span>mdi-window-minimize</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-open"></i>
                <code class="mr-2">f5b1</code>
                <span>mdi-window-open</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-window-restore"></i>
                <code class="mr-2">f5b2</code>
                <span>mdi-window-restore</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-windows"></i>
                <code class="mr-2">f5b3</code>
                <span>mdi-windows</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wordpress"></i>
                <code class="mr-2">f5b4</code>
                <span>mdi-wordpress</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-worker"></i>
                <code class="mr-2">f5b5</code>
                <span>mdi-worker</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wrap"></i>
                <code class="mr-2">f5b6</code>
                <span>mdi-wrap</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wrench"></i>
                <code class="mr-2">f5b7</code>
                <span>mdi-wrench</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-wunderlist"></i>
                <code class="mr-2">f5b8</code>
                <span>mdi-wunderlist</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xaml"></i>
                <code class="mr-2">f673</code>
                <span>mdi-xaml</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xbox"></i>
                <code class="mr-2">f5b9</code>
                <span>mdi-xbox</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xbox-controller"></i>
                <code class="mr-2">f5ba</code>
                <span>mdi-xbox-controller</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xbox-controller-off"></i>
                <code class="mr-2">f5bb</code>
                <span>mdi-xbox-controller-off</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xda"></i>
                <code class="mr-2">f5bc</code>
                <span>mdi-xda</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xing"></i>
                <code class="mr-2">f5bd</code>
                <span>mdi-xing</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xing-box"></i>
                <code class="mr-2">f5be</code>
                <span>mdi-xing-box</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xing-circle"></i>
                <code class="mr-2">f5bf</code>
                <span>mdi-xing-circle</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-xml"></i>
                <code class="mr-2">f5c0</code>
                <span>mdi-xml</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-yeast"></i>
                <code class="mr-2">f5c1</code>
                <span>mdi-yeast</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-yelp"></i>
                <code class="mr-2">f5c2</code>
                <span>mdi-yelp</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-yin-yang"></i>
                <code class="mr-2">f67f</code>
                <span>mdi-yin-yang</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-youtube-play"></i>
                <code class="mr-2">f5c3</code>
                <span>mdi-youtube-play</span>
              </b-col>
              <b-col cols="12" sm="4" class="my-2">
                <i class="mr-2 mdi mdi-zip-box"></i>
                <code class="mr-2">f5c4</code>
                <span>mdi-zip-box</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "MaterialIcons",
  data: () => ({
    page: {
      title: "Material Icons",
    },
    breadcrumbs: [
      {
        text: "Icons",
        disabled: false,
        to: "#",
      },
      {
        text: "Material Icons",
        disabled: true,
      },
    ],
  }),
};
</script>